/**
 * The feature flag definition file
 * 
 * For more information visit this MR:
 * https://gitlab.com/karve.it/karve-plus/-/merge_requests/987


Uses GitLab to set feature flags. The backend then proxies
these feature flags from gitlab passing login information,
allowing us to manage feature states from gitlab.
We can use the domain as the "environment" in production,
allowing us to specify what users on what environment have
access to specific features and manage it from the gitlab UI.

You can also enable or disable features on a per zone basis using configs.

In the frontend, you can use NGRX to select specific features.
You must add features to the features list in the frontend file
called `features.ts` for that feature state to be loaded on
initialization (when we call the branding query, essentially
any time the zone gets set.

For example if you want to add a feature called `use-schedule-v2`
we do the following:

1. Add the feature to GitLab under
[Feature Flags](https://gitlab.com/karve.it/karve-plus/-/feature_flags)
2. Set the strategies you want to use to roll this feature out
3. Add the feature name/ID to `src/app/services/features.ts`
4. On the frontend, you can watch the feature using two methods:

Method a)

```typescript
this.subs.sink = this.brandingService.watchFeature('jobs-v2')
    .subscribe((enabled) => {
      this.viewV2PageAction.visible = enabled;
    });
```

Method b)

```typescript
<div
  *ngIf="brandingService.watchFeature('jobs-v2')"
></div>
```

 */


export const features = [
  'jobs-v2',
  'dispatch',
	'dashboard-v2',
] as const;

export type FeatureName = typeof features[number];
