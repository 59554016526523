import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { jobToolFeature } from '../job-tool.reducer';

import { JobTimelinePipe } from './job-timeline.pipe';

@Component({
  selector: 'app-job-overview',
  standalone: true,
  imports: [
    CommonModule,
    JobTimelinePipe,
  ],
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.scss'],
})
export class JobOverviewComponent implements OnInit {

  job$ = this.store.select(jobToolFeature.selectJob);
  customer$ = this.store.select(jobToolFeature.selectCustomer);
  customerName$ = this.store.select(jobToolFeature.selectCustomerName);
  fields$ = this.store.select(jobToolFeature.selectFields);
  events$ = this.store.select(jobToolFeature.selectEvents);
  loaded$ = this.store.select(jobToolFeature.jobLoaded);

  showMap = false;

  constructor(
    public store: Store,
  ) { }

  ngOnInit(): void {
  }

}
