import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, delay, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';

import { CreateCommentGQL, DeleteCommentsGQL, JobsV2PageGQL, JobsV2PageQueryVariables, ListCommentsWithRepliesGQL, UpdateCommentsGQL } from '../../generated/graphql.generated';



import { generateMutationContext } from '../core/auth/session';


import { sortCommentsWithReplies } from './job-activity/comments.utils';
import { JobToolSubscriptionActions } from './job-state/job-tool-subscription.actions';
import { JobToolActions } from './job-tool.actions';
import { jobToolFeature } from './job-tool.reducer';

export const addComment = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		createCommentGQL = inject(CreateCommentGQL),
	) => {
		return actions$.pipe(
			ofType(JobToolActions.addComment),
			withLatestFrom(store.select(jobToolFeature.selectZone)),
			exhaustMap(([{ input, temporaryCommentId }, zone]) => {

				return createCommentGQL.mutate({
					input,
				}, {
					context: generateMutationContext({
						zone: zone?.id,
						actionId: temporaryCommentId,
					}),
				}).pipe(
					map((result) => JobToolActions.addCommentSuccess({
						comment: result.data?.addComment,
						commentId: temporaryCommentId,
					})),
					catchError((error) => of(JobToolActions.addCommentError({
						error, input, commentId: temporaryCommentId,
					})))
				);
			},
			)
		);
	},
	{ functional: true }
);

export const updateComment = createEffect(
	(
		actions$ = inject(Actions),
		updateCommentGQL = inject(UpdateCommentsGQL),
	) => {
		return actions$.pipe(
			ofType(JobToolActions.updateComment),
			exhaustMap(({ input }) => {

				return updateCommentGQL.mutate({
					input,
				}).pipe(
					map((result) => {
						if (!result.data?.updateComments?.length) {
							return JobToolActions.updateCommentError({
								error: new Error(`Comment not found, cannot update`), input,
							});
						}

						return JobToolActions.updateCommentSuccess({
							comment: result.data?.updateComments[0],
						});
					}),
					catchError((error) => of(JobToolActions.updateCommentError({
						error, input,
					})))
				);
			},
			)
		);
	},
	{ functional: true }
);

export const deleteComment = createEffect(
	(
		actions$ = inject(Actions),
		deleteCommentGQL = inject(DeleteCommentsGQL),
	) => {
		return actions$.pipe(
			ofType(JobToolActions.deleteComment),
			exhaustMap(({ comment }) => {

				return deleteCommentGQL.mutate({
					input: {
						ids: [ comment.id ],
					},
				}).pipe(
					map((result) => {
						if (!result.data?.deleteComments?.commentsDeleted?.length) {
							return JobToolActions.deleteCommentError({
								comment,
								error: new Error(`Comment not found, cannot delete`),
							});
						}

						return JobToolActions.deleteCommentSuccess({
							comment,
						});
					}),
					catchError((error) => of(JobToolActions.deleteCommentError({
						error, comment,
					})))
				);
			},
			)
		);
	},
	{ functional: true }
);

/**
 * Called when we add a remote comment to load more info like
 * the author name
 */
export const loadComment = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		listCommentWithRepliesGQL = inject(ListCommentsWithRepliesGQL),
	) => {
		return actions$.pipe(
			ofType(JobToolSubscriptionActions.remoteCommentAdded),

			// we delay so that we can wait for the add comment query
			// to finish... I dont like this
			delay(300),
			withLatestFrom(store.select(jobToolFeature.selectComments)),
			exhaustMap(([{ input, output }, comments]) => {
				// does the comment already exist?
				// const { comment } = findComment(comments, output.id);
				// if (comment) {
				// 	return of(undefined);
				// }

				return listCommentWithRepliesGQL.fetch({
					filter: {
						ids: [ output.id ],
						objectType: 'Job',
						parentsOnly: false,
						// objectIds: [],
					}
				}).pipe(
					map((result) => {
						const [comment] = result.data?.comments?.comments || [];
						if (!comment) {
							// some very weird remote error happened
							return undefined;
						}

						console.log(`Comment loaded after remote retrieval`);

						return JobToolActions.commentLoaded({
							comment,
						});
					}),
					// TODO: We need a better remote error
					catchError((error) => of(JobToolActions.commentLoadError({
						error, commentId: output.id,
					})))
				);
			},
			)
		);
	},
	{ functional: true }
);

export const tabChanged = createEffect((
	actions$ = inject(Actions),
) => {
	const router = inject(Router);
	const route = inject(ActivatedRoute);
	const store = inject(Store);
	return actions$.pipe(
		ofType(JobToolActions.tabChanged),
        concatLatestFrom(action => store.select(jobToolFeature.selectJobId)),
		tap(([ { name }, jobId ]) => {
			router.navigate([ '/job/v2/', jobId, name ], {
				relativeTo: route,
				queryParams: route.snapshot.queryParams,
			});
		}),
	);
}, { functional: true, dispatch: false })

export const getJobFromParams = createEffect(
	(
		actions$ = inject(Actions),
		jobsV2GQL = inject(JobsV2PageGQL),
		store = inject(Store),
	) => {

		return actions$.pipe(
			ofType(JobToolActions.paramsSet),
			exhaustMap(({ jobId }) => {

				const variables: JobsV2PageQueryVariables = {
					jobId,
					resolve: [
						'discounts',
						'users',
						'locations',
					],
				};

				return jobsV2GQL.fetch(variables, {
					fetchPolicy: 'network-only',
					notifyOnNetworkStatusChange: true,
				}).pipe(map((res) => {
					if (res.loading || !res?.data) {
						return JobToolActions.jobLoading();
					}

					const { jobs, comments } = res.data;
					if (!jobs.jobs?.length) {
						return JobToolActions.jobLoadError({
							error: new Error(`Job not found with id ${ jobId }`),
						});
					}

					const job = jobs.jobs[0];

					return JobToolActions.jobLoaded({
						job,
						comments: sortCommentsWithReplies(comments.comments, false),
						totalComments: comments.total,
					});
				}), catchError((error) => {
					// TODO: fire job load error
					return of(JobToolActions.jobLoadError({ error }))
				}));
			},
			)
		);
	},
	{ functional: true, dispatch: true }
);
