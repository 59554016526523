import { AfterViewInit, Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { PlusAuthenticationService } from '@karve.it/core';
import { JobService } from '@karve.it/features';
import { BulkEditCalendarEventInput, CalendarEvent } from '@karve.it/interfaces/calendarEvents';
import { ListCommentsOutput } from '@karve.it/interfaces/comments';
import { MutationResult, QueryRef } from 'apollo-angular';



import { cloneDeep } from 'lodash';
import { Confirmation, ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { firstValueFrom, lastValueFrom, merge } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { cmdFlags } from '../../cmd';

import { environment } from '../../environments/environment';
import { AddLocationToJobInput, AddTagsToObjectsGQL, AvailableZonesAndCurrentZoneGQL, BaseFieldFragment, BaseZoneWithParentFragment, BulkEditCalendarEventGQL, CalendarEventLocation, CalendarEventLocationInput, CreateCalendarEventGQL, EstimatesJobFragment, EstimatingCreateJobGQL, EstimatingGQL, EstimatingQuery, EstimatingQueryVariables, EstimatingUpdateJobGQL, JobClosedGQL, JobPromotedGQL, JobUserInput, RemoveTagsFromObjectsGQL, ResolveServiceAreaGQL, ResolveServiceAreaQuery, Rule, ServiceAreaQueryMatch, SetFieldValuesGQL, SetFieldValuesMutation, SetFieldValuesMutationVariables, SingleEditInput, UpdateJobInput } from '../../generated/graphql.generated';
import { AppMainComponent } from '../app.main.component';
import { MenuService } from '../base/menu/app.menu.service';
import { CommentsComponent } from '../comments/comments.component';
import { getFieldValue, hasValue, splitName } from '../fields/fields.utils';
import { estimatorDetails } from '../franchise/rules/rules.constants';
import { EventTypeInfo, JOB_ROLE_MAP, JOB_STAGES, schedule } from '../global.constants';
import { getEventCompareFn } from '../jobs/jobs.util';
import { arrPushIfNotExists, arrRemoveValue } from '../js';
import { BrandingService } from '../services/branding.service';
import { DetailsHelperService } from '../services/details-helper.service';
import { EstimateHelperService, LocationSetInput } from '../services/estimate-helper.service';
import { EventHelperService } from '../services/event-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { PageTitleService } from '../services/page-title.service';
import { PermissionService } from '../services/permission.service';
import { PromoteJobService } from '../services/promote-job.service';
import { ResponsiveHelperService } from '../services/responsive-helper.service';
import { DisplayTagsComponent } from '../shared/display-tags/display-tags.component';
import { RuleCheckerService } from '../shared/rule-checker.service';
import { UpdateEventLocationsDialogComponent } from '../shared/update-event-locations-dialog/update-event-locations-dialog.component';
import { ObjectUpdate } from '../utilities/details-helper.util';
import { bufferDebounce } from '../utilities/rxjs.util';

import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { DistanceService } from './distance.service';
import { EstimateBreakdownComponent } from './estimate-breakdown/estimate-breakdown.component';
import { EstimateConfirmationComponent } from './estimate-confirmation/estimate-confirmation.component';
import { EstimatingSaveInfo, JobSavingStage, ModifiedField } from './estimate.interfaces';
import { EstimatingInventoryComponent } from './estimating-inventory/estimating-inventory.component';
import { JobBookingComponent } from './job-booking/job-booking.component';
import { JobInfoComponent } from './job-info/job-info.component';
import { SelectAreaDialogComponent, SelectAreaDialogData, SelectAreaDialogResult } from './select-area-dialog/select-area-dialog.component';

export interface ZoneConfirmationOptions extends Confirmation {

  onHide?: (ev) => void;
  location?: any;
  showAddress?: boolean;
  note?: string;
  nextMessage?: string;
  requireAnswer?: boolean;
}

export interface EventLocationInfo {
  event: EstimatesJobFragment['events'][number];
  locations: {
    currentLocation: CalendarEventLocation;
    newLocation: AddLocationToJobInput & { address: string };
  }[];
}

@Component({
  selector: 'app-estimates',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.scss']
})
export class EstimatesComponent implements OnInit, OnDestroy {

  // CHILDREN
  @ViewChild('customerInfo') customerRef: CustomerInfoComponent;
  @ViewChild('inventory') inventoryRef: EstimatingInventoryComponent;
  @ViewChild('jobInfo') jobRef: JobInfoComponent;
  @ViewChild('breakdown') breakdownRef: EstimateBreakdownComponent;
  @ViewChild('booking') bookingRef: JobBookingComponent;
  @ViewChild('confirmation') confirmationRef: EstimateConfirmationComponent;
  @ViewChild('comments') commentsRef: CommentsComponent;
  @ViewChild('tags') tagsRef: DisplayTagsComponent;

  @HostBinding('id') hostId = 'estimates-page';

  // SUBS
  subs = new SubSink();

  // JOB VARIABLES
  jobId?: string;
  job: EstimatesJobFragment;
  mutating = false;

  // The id of an event that has been created, used to set the active event when the job loads
  newEventId: string;

  fields: BaseFieldFragment[] = [];

  jobSaving: JobSavingStage;
  estimatingQueryRef: QueryRef<EstimatingQuery>;
  needsFinancing = false;
  // The estimated total calculated from the charges in the estimate breakdown
  estimatedTotal = 0;

  // FIELDS VARIABLES
  modifiedFields: ModifiedField[] = [];

  // COMMENTS
  commentsQueryRef: QueryRef<ListCommentsOutput>;

  // STEPS
  steps: MenuItem[];
  activeStep = 0;

  // ZONE CHANGING
  // True if we can see the change zone dialog
  changeZoneDialogVisible = false;
  disableChangeZoneDialogButtons = false;
  changeZoneConfirmationOptions: ZoneConfirmationOptions;
  displayZoneSwitchError = false;

  /**
   * The area ID of the zone to create the job in.
   */
  zoneAssignmentPending: string;

  // DIALOG TOGGLES
  // Whether the clear data dialog can be seeen
  clearDialogVisible = false;
  // Whether the event dialog can be seen
  eventDialogVisible = false;
  // The Dialog for overriding the booking restrictions
  overrideDialogVisible = false;


  // COMPONENT STATE FLAGS
  // Flag for the state of the job, used in the guard
  jobClosed = false;
  // Disables event booking buttons while waiting for query to finish
  eventsUpdating = false;
  // set when we are in the process of changing our zone
  zoneChanging = false;
  // Used to determinie if rules hsould be loaded or already are
  rulesInitialized = false;
  // True if the job is loading
  jobLoading = false;
  // True if the area for the job is being retrieved
  areaLoading = false;

  dockLoading: Promise<any>;

  userSetInParmas = false;

  eventsMarkedAsRequiredByRules: string[] = [];

  get loading() {
    return this.jobLoading || this.jobSaving || this.areaLoading || this.eventsUpdating || this.zoneChanging;
  }

  // If true the user can set the zone for the job manually, value set when job is loaded.
  canSetJobZoneManually = false;

  hasSetTagsPermission$ = this.permissionHandler.watchPermissions(['tags.setObjectTags'])
    .pipe(map((res) => typeof res === 'boolean' ? res : res[0]));

  constructor(
    public estimateHelper: EstimateHelperService,
    public appMain: AppMainComponent,
    private createJobsGQL: EstimatingCreateJobGQL,
    private updateJobs: EstimatingUpdateJobGQL,
    private localNotify: FreyaNotificationsService,
    private availableZonesAndCurrentZoneGQL: AvailableZonesAndCurrentZoneGQL,
    private resolveServiceAreaGQL: ResolveServiceAreaGQL,
    private route: ActivatedRoute,
    private router: Router,
    private plusAuth: PlusAuthenticationService,
    private addTagsToObjectsGQL: AddTagsToObjectsGQL,
    private removeTagsFromObjectsGQL: RemoveTagsFromObjectsGQL,
    private ruleChecker: RuleCheckerService,
    private distanceService: DistanceService,
    private detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
    private promoteJobService: PromoteJobService,
    private jobService: JobService,
    public dialogService: DialogService,
    private menuService: MenuService,
    private eventHelper: EventHelperService,
    private titleService: PageTitleService,
    private brandingSvc: BrandingService,
    private estimatingGQL: EstimatingGQL,
    private setFieldValuesGQL: SetFieldValuesGQL,
    public responsiveHelper: ResponsiveHelperService,
    private confirmService: ConfirmationService,
    private createEventGQL: CreateCalendarEventGQL,
    private bulkEditCalendarEventGQL: BulkEditCalendarEventGQL,
    private jobPromotedGQL: JobPromotedGQL,
    private jobClosedGQL: JobClosedGQL,
    private permissionHandler: PermissionService,
  ) {
    this.steps = [
      { label: 'Customer', id: 'customer' },
      { label: 'Location', id: 'location' },
      { label: 'Inventory', id: 'inventory' },
      { label: 'Products & Services', id: 'products-services' },
      { label: 'Booking', id: 'booking' },
      { label: 'Confirmation', id: 'confirmation' }
    ];
  }

  // Allows route guard to listen for refresh, close etc.
  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event) {
    if (this.hasUnsavedChanges() && environment.production) {
      event.preventDefault();
      const txt = 'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.';
      event.returnValue = txt;
      confirm(txt);
      return txt;
    }
  }

  ngOnInit(): void {

    // Check if we have a job in progress or selected, if so load it, if not then create a job
    this.subs.sink = this.route.paramMap.subscribe(async paramMap => {
      const zone = this.route.snapshot.queryParams?.zone;

      if (paramMap.get('jobId')) {
        const jobId = paramMap.get('jobId');

        // loading an existing job...
        this.estimateHelper.fetchingNewJob.next(true);
        this.retrieveJob(jobId, zone);

      } else {
        // While starting a new job, force the user to step zero.
        this.startNewJob(zone);
        this.router.navigate([], { queryParams: { step: 0 }, queryParamsHandling: 'merge' });
      }

      this.menuService.setSidebar();
    });

    // Go to the step based on query param
    this.subs.sink = this.route.queryParams.subscribe((paramMap) => {
      let step = paramMap.step;

      const userId = paramMap.userId;
        if (userId) {
          this.userSetInParmas = true;
          this.customerRef.setUserFromParameters(userId);
        }

      if (step === '') {
        step = 0;
      }

      if (step && Number.isNaN(+step)) {
        // step is a string
        const stepIndex = this.steps.findIndex(((s) => s.id === paramMap.step));
        if (stepIndex >= 0 && stepIndex < this.steps.length) {
          this.activeStep = stepIndex;
        } else {
          this.activeStep = 0;
        }
      } else if (!Number.isNaN(+step)) {
        // step is a number
        let stepIndex = +step;
        if (stepIndex < 0) {
          stepIndex = 0;
        } else if (step >= this.steps.length) {
          stepIndex = this.steps.length - 1;
        }

        this.activeStep = stepIndex;

      }

      // // Prevents changed after checked error
      // this.appMain.cd.detectChanges();
    });

    this.distanceService.reset();
    this.estimateHelper.setStepIndex.subscribe((step) => {
      this.activeStep = step;
      this.indexChanged();
    });

    // Listen for changes to the field values
    this.subs.sink = this.estimateHelper.fieldModified.subscribe((modifiedField) => {
      this.updateModifiedFields(modifiedField);
    });

    this.subs.sink = this.estimateHelper.panelSync.subscribe((res) => {
      this.syncValues(res.data);
    });

    this.subs.sink = this.estimateHelper.locationSet.subscribe((res) => {
      this.onStartLocationSet(res);
    });

    this.subs.sink = this.freyaHelper.transactionCreated.subscribe((res) => { // Listen for changes to the job totals
      this.retrieveJob();
    });

    this.loadRules();
    this.subs.sink = this.brandingSvc.currentZone().subscribe(() => {
      this.reloadConfig();
      this.resolveAvailableZones();
    });

    this.subs.sink = this.distanceService.distancesUpdated.subscribe(() => {
      this.onEstimateUpdated();
    });

    // Open Confirmation Dialog
    this.subs.sink = this.estimateHelper.openConfirmationDialog.subscribe((res) => {
      this.openEventDialog();
    });

    // Listen for changes so we can refetch if any of the following change
    this.subs.sink = this.detailsHelper.getObjectUpdates([
      'Events',
      'Tags',
      'Jobs',
      'Fields',
      'Transactions',
      'Invoice',
      'User'
    ])
      .pipe(
        bufferDebounce(100)
      )
      .subscribe(async (updates: ObjectUpdate[]) => {

        const refetchFalse = Boolean(updates.find((u) => u.type === 'Jobs' && u.action === false)) && updates?.length === 1;

        // We were only passed one job update with refetch as false
        if (refetchFalse || !this.estimatingQueryRef) {
          return;
        }

        const eventUpdate = updates.find((u) => u.type === 'Events');

        let activeEventCancelled: boolean;

        if (eventUpdate) {
          // If we revecieved an event update then set eventsUpdating to false
          this.eventsUpdating = false;

          // Check if the active event was cancelled
          const activeEventId = this.route.snapshot.queryParams?.activeEventId;

          const activeEventUpdated = eventUpdate.id === activeEventId;

          const eventCancelled = eventUpdate?.update?.status === 'cancelled';

          activeEventCancelled = eventCancelled && activeEventUpdated;
        }

        // If the active event was cancelled then remove the query param
        if (activeEventCancelled) {
          await this.router.navigate(
            ['estimating', this.job.id],
            { queryParams: { activeEventId: null }, queryParamsHandling: 'merge' }
          );
        }

        this.retrieveJob();
    });

    this.subs.sink = this.estimateHelper.addRequiredEvent.subscribe(async (res) => {
      this.addRequiredEvent(res);
      await this.trySaveJob();
    });

    this.subs.sink = merge(
      this.jobPromotedGQL.subscribe(),
      this.jobClosedGQL.subscribe(),
    )
    .subscribe((res) => {

      let jobId: string;
      let message: string;
      let reason: string;

      if ('jobPromoted' in res.data) {

        jobId = res.data?.jobPromoted?.jobId;
        message = 'Job promoted to invoice';
        reason = res.data?.jobPromoted?.reason;

      } else if ('jobClosed' in res.data) {

        jobId = res.data?.jobClosed?.jobId;
        message = 'Job closed';
        reason = res.data?.jobClosed?.reason;
      }

      if (jobId === this.job.id) {
        this.localNotify.success(message, reason);
        this.retrieveJob();
      }

    });

  }

  reloadConfig() {
    this.loadRules();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.commentsQueryRef = undefined;
    this.estimatingQueryRef = undefined;
    this.titleService.setCustomPageTitle({});
  }

  async reset() {
    this.jobId = undefined;
    this.job = undefined;

    if(!this.userSetInParmas){
      this.customerRef.reset();
    }
    this.jobRef.reset();
    this.inventoryRef.reset();
    this.breakdownRef.reset();
    this.bookingRef.reset();
    this.confirmationRef.reset();
    this.menuService.pushJob(undefined, 0);

    // console.trace(`NAVIGATING reset`);
    const res = await this.router.navigate(['/estimating'], {
      queryParams: {
        step: 'customer',
      },
    });

    this.userSetInParmas = false;

    this.populateValues();
  }

  async changeZoneForJob(zoneId: string) {
    if (this.plusAuth.contextedZoneId === zoneId) { return; }
    this.zoneChanging = true;
    await this.plusAuth.setContext(zoneId);
    this.localNotify.success('Zone changed to access job');
    this.zoneChanging = false;

  }

  /**
   * Handle initializing the job query
   *
   * @param jobId The id of the job
   * @param zoneId The zone for the job
   */
  async initJobQuery(jobId?: string, zoneId?: string) {
    // If we don't have a job Id then reset the component
    if (!jobId) {
      // this.reset();
      return;
    }

    this.jobId = jobId;

    // Change zone if we are in the wrong zone for the job
    if (zoneId && this.brandingSvc.currentZone().value?.id !== zoneId) {
      await this.changeZoneForJob(zoneId);
    }
    // await this.setDock();

    // const { input, jobQueryInput } = this.getJobQueryInput();
    // this.jobQueryRef = this.jobService.watchJobs(input, jobQueryInput, { fetchPolicy: 'cache-and-network' });

    this.estimatingQueryRef = this.estimatingGQL.watch(this.getJobQueryInput(), {fetchPolicy: 'cache-and-network'});

    return new Promise((resolve, reject) => {
      this.subs.sink = this.estimatingQueryRef.valueChanges.subscribe(async (res) => {
        // Handle loading state for the component
        this.setJobLoading(res.loading);
        if (res.loading || !res.data || this.zoneChanging ) {
          return;
        }

        const [job] = cloneDeep(res.data.jobs.jobs);

        if (!job) {
          //this.reset();
          return;
        }

        // Set the job value here and in the children
        this.job = job as unknown as EstimatesJobFragment;
        //Sort the events here so they are always in the same order in sub components
        this.job.events.sort(getEventCompareFn('sequentialOrder'));
        this.setJobInChildren();

        this.canSetJobZoneManually = this.canSetZone();

        // Set title
        this.titleService.setCustomPageTitle({
          jobCode: this.job.code,
        });

        // If we are trying to load a cancelled job notify the user and then change the URL
        if (this.job?.stage === JOB_STAGES.cancelled.name || (this.job as unknown as EstimatesJobFragment).archivedAt) {
          const status = this.job?.stage === JOB_STAGES.cancelled.name ? 'a cancelled' : 'an archived';
          this.localNotify.warning(`Cannot load ${status} job on the estimator`);
          this.menuService.pushJob(this.job, 0, true);
          await this.router.navigate(['/job', this.job.id]);
          return;
        }

        await this.setPageUrl();

        // Return if an invalid id is in local storage
        if (!this.job) {
          this.setJobLoading(false);
          return;
        }

        // Resolve the correct zone to auth into, should not auth into areas
        const jobAuthZone = this.job?.zone.type === 'area' ? this.job.zone.parent : this.job.zone;

        if (this.plusAuth.contextedZoneId !== jobAuthZone.id && !this.areaLoading) {
          await this.changeZoneForJob(jobAuthZone.id);
        }

        // Update the query variables for the fields and comments
        this.updateDependantQueries();

        const fields = res.data.fields.fields;
        this.fields = fields;

        // populate the rest of the values
        await this.populateValues();

        // Update the active event, needs to happen after populate values
        if (this.newEventId) {
          console.log('New Event Id');
          await this.router.navigate(
            ['estimating', this.job.id],
            { queryParams: {activeEventId: this.newEventId }, queryParamsHandling: 'merge' }
          );
          this.newEventId = undefined;
        }

        // MUST OCCUR AFTER POPULATE VALUES
        this.populateValuesFromFields(fields);

        this.setJobLoading(false);

        resolve(this.job);
      }, (err) => {
        console.error('Error retrieving job', err);
        this.setJobLoading(false);
        //this.reset();
      });
    });
  }

  getJobQueryInput(jobId?: string) {
    // If there is a job, default to job?.id first
    // Only default to jobId fetched from params if no job has been loaded yet
    // Otherwise, you may fetch a previous job since jobId is not necessarily up-to-date with the current job
    jobId = jobId || this.job?.id || this.jobId;

    const input = {
      filter: {
        jobAncestorIds: [jobId],
      },
      jobId,
      objects: [jobId],
    } as EstimatingQueryVariables;

    return input;
  }

  retrieveJob(jobId?: string, zoneId?: string) {
    if (!this.estimatingQueryRef) {
      return this.initJobQuery(jobId, zoneId);
    }
    return this.estimatingQueryRef.refetch(this.getJobQueryInput(jobId));
  }

  async startNewJob(zone: string) {
    await this.retrieveJob(undefined, zone);
    this.customerRef.setJobOrigin();
    this.checkRules();
  }

  /**
   * Populates the values of the forms based on the corresponding fields
   *
   * @param fields List of fields to sort through
   */
  populateValuesFromFields(fields: BaseFieldFragment[]){
    if (!fields?.length) { return; }

    this.estimateHelper.fieldValues.next(fields);

    this.confirmationRef.setDisplayValues(fields as any);

    for (const field of fields) {

      // Skip fields that don't have a value set
      if (!hasValue(field)) { continue; }

      const {namespace, key} = splitName(field);

      // Assign MISC Field Values
      if (
        namespace === 'misc'
          || key === 'needsFinance'
          || key === 'extras'
          || key === 'moreThan10Items'
          || key === 'partialMove'
          || key === 'howDidTheyHearAboutUs'
      ) {
        this.assignMiscValues(key, field.values[0].value);
        continue;
      }

      // Assign Values based on Forms
      const form = this.getFormByNamespace(namespace);

      if (!form) { continue; } // Namespace form can't be found

      if (!form.controls[key]) { continue; } // No matching property for the Form

      form.controls[key].setValue(getFieldValue(field));
    }

    this.onEstimateUpdated();
  }

  loadRules() {
    this.rulesInitialized = true;
    this.ruleChecker.reset();
    this.ruleChecker.form = estimatorDetails.form;
    this.ruleChecker.loadRules();
  }

  /**
   * @returns rule data from all sub components
   */
  getRuleData() {
    return {
      ...this.jobRef.getRuleData(),
      // ...this.customerRef.getRuleData(),
      ...this.confirmationRef.getRuleData(),
      alwaystrue: true,
    };
  }

  checkRules() {
    const data = this.getRuleData();
    const result = this.ruleChecker.updateData(data);
    const oseRequired = result?.rulesTriggered?.find((r) => r.trigger.key === 'trigger.require-estimate');

    const stageInfo = JOB_STAGES[this.job?.stage || ''];
    if (!stageInfo) { return; }

    if (stageInfo.applyEstimateRequired) {

      this.applyEstimateRequiredTrigger(oseRequired);


    }
  }

  /**
   * Apply the estimate required tag if it is not already set on the job
   */
  applyEstimateRequiredTrigger(ruleTriggered?: Rule) {

    // don't apply the rule if the job hasn't been saved yet
    // const jobSaved = this.job && this.job.tags && this.job.stage;
    // if (!jobSaved) { return; }

    const estimatingKey = 'estimating';

    if (ruleTriggered) {
      // Mark event as "marked as required by rules"
      // So component knows to remove it from required events if rule no longer triggered
      arrPushIfNotExists(this.eventsMarkedAsRequiredByRules, estimatingKey);

      const eventTypeStatuses = this.estimateHelper.getEventTypeStatusesOnJob(this.job, true);
      const estimatingEventType = eventTypeStatuses[estimatingKey];

      // don't apply the rule if the job already has a status for the estimating event
      if (estimatingEventType) { return; }

      return;
    }
    if (this.eventsMarkedAsRequiredByRules.includes(estimatingKey)) {
      arrRemoveValue(this.eventsMarkedAsRequiredByRules, estimatingKey);
    }
  }

  /**
   * Sets the url for the page based on job and current step.
   */
  setPageUrl() {
    // Reset the step to the first one if it isn't valid
    if (!this.steps[this.activeStep]) {
      this.activeStep = 0;
    }

    const step = this.steps[this.activeStep]?.id;
    if (this.job) {
      this.menuService.pushJob(this.job, step);
      // Update the URL with the step
    } else {
      this.menuService.pushJob(undefined, step);
    }

    const command = ['/estimating'];
    if (this.job) {
      command.push(this.job.id);
    }

    // console.trace(`NAVIGATING setPageURL`);
    return this.router.navigate(command, {
      queryParamsHandling: 'merge',
      queryParams: {
        step: step || this.activeStep,
      },
      replaceUrl: true,
    });
  }

  async trySaveJob(
    preventRefetch = false,
    manual = false,
    setZone?: string,
  ): Promise<EstimatesJobFragment> {
    try {
      const res = await this.saveJob(preventRefetch, manual, setZone);

      return res;
    } catch (err) {
      this.localNotify.addToast.next({
        summary: 'Failed to save job',
        detail: err?.message,
        severity: 'error',
      });
      this.setJobSaving(JobSavingStage.NOT_SAVING);
      if (!this.estimatingQueryRef) {
        await this.initJobQuery(this.jobId);
      } else {
        await this.retrieveJob();
      }
      // if (!preventRefetch) {
      //   await this.retrieveJob();
      // }

      throw err;
    }
  }

  canSave() {
    if (this.jobLoading || this.jobSaving || this.areaLoading) {
      return false;
    }

    const customerFormValid = this.customerRef?.customerForm?.valid;
    if (!this.job && !customerFormValid) {
      return false;
    }

    return true;
  }

  /**
   * Handles the saving of the job and all sub-items of the Job
   *
   * @param preventRefetch True if we are navigating away from this page
   * @param manual True if the user clicked the save button manually
   */
  async saveJob(
    preventRefetch = false,
    manual = false,
    setZone?: string,
  ): Promise<EstimatesJobFragment> {
    this.checkRules();

    //to keep newJobCreated = true only after first saveJob till second SaveJob
    if (this.estimateHelper.newJobCreated) {
      this.estimateHelper.newJobCreated = false;
    }

    // We don't want to save while we are in the process of loading or saving
    if (this.jobLoading || this.jobSaving || this.areaLoading) {
      return;
    }
    this.setJobSaving(JobSavingStage.SAVING);
    const customerFormValid = this.customerRef.customerForm.valid;
    let created = false;
    // const startLocationValid = this.jobRef.locationRefs.find((lr) => lr.type === 'start')?.baseLocationForm.valid;

    if (!this.job && customerFormValid) {
      await this.createJob('lead');
      this.estimateHelper.newJobCreated = true;
      created = true;
    } else if (!this.job) {
      this.setJobSaving(JobSavingStage.NOT_SAVING);
      // we can only create the job if we have a valid customer form
      return;
    }

    let stage: string;
    // Promote to an estimate
    if (
      this.job?.stage === 'lead' &&
      this.breakdownRef.addedCharges?.length
    ) {
      stage = 'estimate';
    }

    // console.log(`Saving job`, { customerFormValid, startLocationValid, promoted, created });

    if (created) {
      this.checkRules();
    }
    // Return if no changes have ocured
    if (!this.hasUnsavedChanges() && !created && !stage && !setZone) {
      this.setJobSaving(JobSavingStage.NOT_SAVING);
      if (manual) {
        this.localNotify.success('Job is already up to date.');
      }
      return null;
    }

    // The Promises that will generate our input and list of promises for later.
    const prerequisitePromises: Promise<EstimatingSaveInfo | void>[] = [];
    prerequisitePromises.push(this.jobRef.saveJobInfo());
    prerequisitePromises.push(this.customerRef.saveCustomer());
    prerequisitePromises.push(this.breakdownRef.saveCharges());
    prerequisitePromises.push(this.setFieldValues());
    prerequisitePromises.push(this.breakdownRef.saveUpdatedEventsOrder());

    // Not Precenting Refetching
    prerequisitePromises.push(this.inventoryRef.saveInventory());

    // Only update the fields after they have been retrieved
    // if (!this.fields){
    //   await this.retrieveFields();
    // }

    const results = await Promise.all(prerequisitePromises);

    const eventLocationsThatNeedUpdating = this.estimateHelper.getEventLocationsThatNeedUpdating(results, this.job, this.jobRef);

    let updateEventLocationsConfirmed: boolean;

    if (eventLocationsThatNeedUpdating?.length) {

      const hasLockedEvents = eventLocationsThatNeedUpdating.some((e) => this.freyaHelper.lockDate > e.event.end);

      if (hasLockedEvents) {
        const msg = 'Job location will be updated but cannot automatically update event locations'
        + ' as some events end before the lock date';
        this.localNotify.warning(msg);
      } else {
        updateEventLocationsConfirmed = await this.confirmUpdateEventLocations(eventLocationsThatNeedUpdating);
      }
    }

    let metadata: { [key: string]: string };
    let input: UpdateJobInput = {
      jobId: this.jobId,
      stage,
    };

    //If user updated order of events in job, set eventsOrderChangedByUser field to that job
    const updatedEventsOrder = (await this.breakdownRef.saveUpdatedEventsOrder()).promises.length;
    if (updatedEventsOrder) {
      input.eventsOrderChangedByUser = true;
    }

    const promises = [];
    const tagIds: string[] = [];
    const removeTagIds: string[] = [];

    // Merge the results
    for (const result of results) {
      if (!result) { continue; }
      if (result.metadata) {
        metadata = { ...metadata, ...result.metadata };
      }

      input = {
        ...result.input,
        ...input,
        setZone,
      };

      if(result.tagIds?.length){
        tagIds.push(...result.tagIds);
      }
      if(result.removeTagIds?.length){
        removeTagIds.push(...result.removeTagIds);
      }

      promises.push(...result.promises);
    }

    if (updateEventLocationsConfirmed) {
      await this.updateEventLocations(eventLocationsThatNeedUpdating);
    }

    if (removeTagIds?.length) {
      promises.push(this.removeTagsFromObject(this.jobId, removeTagIds));
    }

    if (tagIds?.length) {
      promises.push(this.addTagsToObject(this.jobId, tagIds));
    }

    if (metadata) {
      input.metadata = metadata;
    }

    const hasSalesAgent = this.job?.users?.some((u) => u.role === JOB_ROLE_MAP.salesAgentRole);

    const addSalesAgentManually = input.addUsers?.some((u) => u.role === JOB_ROLE_MAP.salesAgentRole);

    // If the job has no sales agent and the user does not intend to add one, assign the current user as the sales agent
    if (this.job && !hasSalesAgent && !addSalesAgentManually) {

      input.addUsers = input.addUsers || [];

      input.addUsers.push({
        role: JOB_ROLE_MAP.salesAgentRole,
        userId: this.plusAuth.user.id,
      });
    }

    await Promise.all(promises);

    // if metadata is the same as the current job then don't save it
    let saveMetadata = false;
    for (const key in input.metadata) {
      if (!this.job?.metadata || this.job.metadata[key] !== input.metadata[key]) {
        saveMetadata = true;
        break;
      }
    }
    if (!saveMetadata) {
      delete input.metadata;
    }

    console.log(this.jobId, this.job);

    // only update the job if we actually need to
    // here we check if the input object is "empty" or just has jobId
    if (JSON.stringify(input) !== JSON.stringify({ jobId: input.jobId })) {
      // updateJob fires an object updated query which will ask us to retrieve the job
      // so if this is called then we dont need to retrieve the job below, hence
      // the if/else
      await this.updateJob(input);
    } else if (this.estimatingQueryRef && !preventRefetch) {
      // if we aren't udpating the job then we still have to retrieve it
      this.setJobSaving(JobSavingStage.RETRIEVING_VALUES);
      await this.retrieveJob();
    }

    if (!this.estimatingQueryRef) {
      // if the job was created we will need to initialize the job query ref
      this.setJobSaving(JobSavingStage.RETRIEVING_VALUES);
      await this.initJobQuery(this.jobId);
    }

    this.setJobSaving(JobSavingStage.NOT_SAVING);

    this.localNotify.success('Job Saved');

    // Returns the current job to match the promise format for the other calls
    return this.job;
  }

  confirmUpdateEventLocations(eventLocations: EventLocationInfo[]) {

    const ref = this.dialogService.open(UpdateEventLocationsDialogComponent, {
      header: 'Update Event Locations?',
      contentStyle: this.freyaHelper.getDialogContentStyle('1.5rem'),
      width: this.responsiveHelper.dialogWidth,
      closable: false,
      data: {
        eventLocations,
      },
    });

    return ref.onClose.pipe(take(1)).toPromise();
  }

  async updateEventLocations(eventLocations: EventLocationInfo[]) {

    const edits: BulkEditCalendarEventInput = { edits: []};

    for (const event of eventLocations) {

      const addLocations: CalendarEventLocationInput[] = [];

      const removeLocations: string[] = [];

      for (const locationInfo of event.locations) {

        const { currentLocation, newLocation } = locationInfo;

        addLocations.push({
          locationId: newLocation.locationId,
          type: currentLocation.type,
          order: currentLocation.order,
          estimatedTimeAtLocation: currentLocation.estimatedTimeAtLocation,
        });

        removeLocations.push(currentLocation.id);
      }

      const input: SingleEditInput = {
        id: event.event.id,
        edit: {
          setLocations: {
            addLocations,
            removeLocations,
          }
        }
      };

      edits.edits.push(input);

    }

    try{
      await this.bulkEditCalendarEventGQL.mutate(edits).toPromise();
      this.localNotify.success('Event locations updated');
    }catch(e){
      console.error(e);
      this.localNotify.error(`Error updating event locations. ${e}`);
    }

  }

  /**
   * Add tags to the job
   *
   * @param jobId Id of the job
   * @param tagIds Ids of the tags
   * @returns Promise
   */
  addTagsToObject(jobId: string, tagIds: string[]): Promise<any>{
    return new Promise((resolve, reject) => {

      this.addTagsToObjectsGQL.mutate({
        objects: [jobId],
        tags: tagIds,
        private: false,
        order: 0,
        objectLabel: 'Job',
      }).subscribe((res) => {
          resolve(true);
        }, (err) => {
          console.error(err);
          reject(err);
        });

      // this.tagService.addTagToObject(jobId, tagIds, false, 0).subscribe((res) => {
      //   resolve(true);
      // }, (err) => {
      //   console.error(err);
      //   reject(err);
      // });
    });
  }
  /**
   * Remove tags from the job
   *
   * @param jobId Id of the job
   * @param tagIds Ids of the tags
   * @returns Promise
   */
  removeTagsFromObject(jobId: string, tagIds: string[]): Promise<any>{
    return new Promise((resolve, reject) => {
      this.removeTagsFromObjectsGQL.mutate({objects: [jobId], tags: tagIds}).subscribe((res) => {
        resolve(true);
      }, (err) => {
        console.error(err);
        reject(err);
      });
      // this.tagService.removeTagsFromObject(jobId, tagIds).subscribe((res) => {
      //   resolve(true);
      // }, (err) => {
      //   console.error(err);
      //   reject(err);
      // });
    });
  }

  openZone() {
    if (!this.job?.zone) { return; }
    if (this.job?.zone.type === 'area') {
      this.detailsHelper.open('area', {id: this.job?.zone.id});
    }
  }

  openCustomer() {
    const customer = this.job?.users?.find((c) => c.role === 'customer');
    if (customer) {
      this.detailsHelper.open('users', {id: customer.user.id});
    }
  }

  /**
   * @returns true if the job is not loading, restrictions are disabled, and zone setting is not blocked.
   */
  canSetZone() {
    if (!this.job) {
      return false;
    }
    if (this.jobLoading) {
      return false;
    }

    if (this.isZoneSettingBlocked()) {
      return false;
    }

    return true;
  }

  /**
   *
   * @returns true if the job has charges, events, transactions.
   */
  isZoneSettingBlocked() {
    if (!this.job) {
      return true;
    }

    if (this.job.charges?.length) {
      return true;
    }

    if (this.job.transactions?.length) {
      return true;
    }

    return false;
  }

  async openSelectAreaDialog(
    opts: {
      actionRequired?: boolean;
      skipSave?: boolean;
      closestAreas?: ServiceAreaQueryMatch[];
      header?: string;
      description?: string;
      onlyShowCurrentSubzones?: boolean;
    },
  ) {
    opts = opts || {};
    opts.header = opts.header || 'Select an area';
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing
    opts.onlyShowCurrentSubzones = opts.onlyShowCurrentSubzones ?? cmdFlags.SelectZone_ShowOnlySubZones;


    const ref = this.dialogService.open(SelectAreaDialogComponent, {
      header: opts.header,
      closable: !opts.actionRequired,
      dismissableMask: !opts.actionRequired,
      contentStyle: this.freyaHelper.getDialogContentStyle('1.5rem'),
      data: {
        job: this.job,
        showCancel: !opts.actionRequired,
        onlyShowAreas: cmdFlags.SelectZone_ShowAreas,
        onlyShowCurrentSubzones: opts.onlyShowCurrentSubzones,
        description: opts.description,
        showAreaWarning: true,
        closestAreas: opts.closestAreas,
      } as unknown as SelectAreaDialogData,
    });


    // on zone select, set zone
    return new Promise<BaseZoneWithParentFragment>((resolve, reject) => {
      const sub = ref.onClose.subscribe(async (res: SelectAreaDialogResult) => {
        sub.unsubscribe();

        if (opts.skipSave) {
          return resolve(res.zone);
        }

        if (!res) { return resolve(undefined); }

        if (res.error) {
          this.localNotify.error('Error loading areas to select', res.error.message);
          return resolve(res.zone);
        }

        // if job or zone were not returned (They are expected); return
        if (!res.job || !res.zone) {
          return resolve(res.zone);
        }

        // if returned job is different then the job we were editing, return
        if (res.job?.id !== this.job.id) { return resolve(res.zone); }
        // if the job zone is the same, return
        if (res.zone.id === this.job.zone.id) { return resolve(res.zone); }

        if (!this.canSetZone()) { return resolve(res.zone); }

        console.log('Setting zone', res.zone);
        await this.trySaveJob(true, false, res.zone.id);
        this.bookingRef.jobAreaChanges$.next(res.zone.id);
        this.breakdownRef.jobZoneSet$.next(res.zone.id);
        this.areaLoading = true;

        console.log(`Zone set to ${ res.zone.id }`);

        let contextZone: string;
        if (res.zone.type === 'area') {
          contextZone = res.zone.parent?.id;
        } else if (res.zone.type === 'franchise') {
          contextZone = res.zone.id;
        }

        // set the new context before retrieving the job (preventRefetch is set to true above)
        if (contextZone && contextZone !== this.plusAuth.contextedZoneId) {
          console.log(`Contexting into ${ contextZone }`);
          await this.plusAuth.setContext(contextZone);
        }
        await this.retrieveJob();
        this.areaLoading = false;
        return resolve(res.zone);
      });
      this.subs.sink = sub;

    });

  }

  /**
   * @returns true if the job has unsaved changes.
   */
  hasUnsavedChanges(): boolean {
    // console.log({
    //   customer: this.customerRef.hasChanges(),
    //   job: this.jobRef.startLocationForm.dirty || this.jobRef.endLocationForm.dirty,
    //   inventory: this.inventoryRef.inventoryForm.dirty,
    //   breakdown: this.breakdownRef.hasChanges(),
    //   fields: this.modifiedFields?.length,
    // });

    return this.job && Boolean(
      // Customer
      this.customerRef.hasChanges()
      // Job Info
      || this.jobRef.hasChanges()
      // Inventory
      || this.inventoryRef.hasChanges()
      // Products and Services
      || this.breakdownRef.hasChanges()
      // fields changed
      || this.modifiedFields?.length
    );
  }

  async indexChanged() {
    if(!this.jobId && ['customer', '0'].includes(this.route.snapshot.queryParams.step)){
      // Shall only execute while creating a new job
      await this.saveCustomerStep();
    } else {
      await this.setPageUrl();
      await this.trySaveJob();
    }
  
    this.menuService.setSidebar();
    this.bookingRef.clearOverlays();

    if (this.activeStep === 4) {
      // Timeout ensures the element has actually been unhidden before it tries to render again.
      setTimeout(() => {
        this.bookingRef.rerenderCalendar();
      }, schedule.rerenderTimeout);
    }

    if(this.activeStep === 5){
      // Upon visiting the confirmation step, refresh the inventories (nested in the confirmation step)
      this.confirmationRef.refreshInventories();
    }
  }

  /**
   * Update the form values based on the panel data.
   *
   * @param data The data from the estiamting notes panel
   */
  async syncValues(data) {

    this.setFormValues(this.customerRef.customerForm, data.customerInfo);

    this.jobRef.syncLocationsFromNotes(data.jobInfo);

    // this.setFormValues(this.inventoryRef.inventoryForm, data?.inventoryInfo);

    this.needsFinancing = data.needsFinancing;
    if (data.needsFinancing) {
      this.updateModifiedFields({ name: 'customer.needsFinance', value: data.needsFinancing });
    }

    this.bookingRef.setPreferences(data.preferredDate, data.preferredTime, data.flexibility);

    // Update Fields Here
    if (data.preferredDate) {
      this.updateModifiedFields({ name: 'booking.preferredDate', value: data.preferredDate });
    }

    if (data.preferredTime) {
      this.updateModifiedFields({ name: 'booking.preferredTime', value: data.preferredTime });
    }

    if (data.flexibility) {
      this.updateModifiedFields({ name: 'booking.flexibility', value: data.flexibility });
    }

    await this.trySaveJob();
  }

  /**
   * Populate the values of the forms based on the job datas
   */
  populateValues() {
    if (this.areaLoading) { return; }

    if (this.job?.id){
      this.commentsRef.objectId = this.job.id;
      //this.commentsRef.fetchComments();
      ////commented due to changes in onChange logic in comments.component
    }
    if (this.tagsRef && this.job){
      this.tagsRef.updateTags(this.job?.tags);
    }

    this.customerRef.setCustomer(this.job);
    const date = getFieldValue(this.fields.find((f) => f.name === 'booking.preferredDate'));
    this.customerRef.setTimelineIfNotSet(date);

    this.jobRef.setJob(this.job);

    // this.inventoryRef.job = this.job;

    // console.log(this.job);
    this.breakdownRef.setCharges(this.job);
    // this.breakdownRef.getProductsForArea();

    this.bookingRef.setEventData(this.job);

    // this.confirmationRef.setEvents(this.job as EstimatesJobFragment);

    this.onEstimateUpdated();
  }

  /**
   * Updates the queries that require the current job Id
   */
  updateDependantQueries() {
    this.commentsRef.objectId = this.job.id;
    //this.commentsRef.fetchComments();
    //commented due to changes in onChange logic in comments.component
  }

  /**
   * Assign values that do not nicely fit into the form based structure
   */
  assignMiscValues(key: string, value) {
    if (key === 'needsFinance') {
      this.confirmationRef.needsFinancing = value;
      this.needsFinancing = value;
    } else if (key === 'signatureRequired') {
      this.confirmationRef.signatureRequired = value;
    } else if (key === 'signedAt') {
      this.confirmationRef.signedAt = value;
    } else if (key === 'extras') {
      this.jobRef.extras = JSON.parse(value);
    } else if (key === 'partialMove') {
      this.jobRef.additionalFieldsForm.controls.partialMove.setValue(value);
    } else if (key === 'moreThan10Items') {
      this.jobRef.additionalFieldsForm.controls.moreThan10Items.setValue(value);
    } else if (key === 'howDidTheyHearAboutUs') {
      this.customerRef.setHowDidYouHearAboutUs(value);
    }
  }

  /**
   * Set the values for fields if they have been modified
   *
   * @returns a promise that sets the field values
   */
  async setFieldValues() {
    if (!this.modifiedFields?.length) { return; }

    const setFieldsInput = {
      fields: this.modifiedFields.map((mf) => ({fieldName: mf.name, value: mf?.value })),
      objects: [this.jobId],
      objectLabel: 'Job',
    } as SetFieldValuesMutationVariables;

    for (const field of this.modifiedFields) {
      const [namespace, key] = field.name.split('.');

      const form = this.getFormByNamespace(namespace);
      form?.controls[key]?.markAsPristine();
    }

    return new Promise<void>((resolve, reject) => {
      this.setFieldValuesGQL.mutate(setFieldsInput).subscribe((res) => {
        this.modifiedFields = [];
        resolve();
      }, (err) => {
        reject();
      });
    });
  }

  /**
   * Get the correct form for the property based on the fields namespace
   *
   * @param namespace namespace of the field
   * @returns the correct form for assigning values
   */
  getFormByNamespace(namespace): UntypedFormGroup {
    let form;
    switch (namespace) {
      case 'customer':
        form = this.customerRef.customerForm;
        break;
      case 'booking':
        form = this.bookingRef.preferencesForm;
        break;
      case 'inventory':
        // form = this.inventoryRef.weightForm;
    }

    return form;
  }

  /**
   * Clears the values in local storage, resets the route and then reloads.
   */
  clearValues() {
    this.clearDialogVisible = false;
    this.reset();
  }

  openClearDialog() {
    this.clearDialogVisible = true;
  }

  async resolveAvailableZones() {

    const { data } = await this.availableZonesAndCurrentZoneGQL
      .fetch({
        skip: 0,
        limit: 200,
        search: '',
      })
      .toPromise();

    return data;
  }

  async determineAreasFromMatchedAreasAndAvailableZones(
    matchedArea: ServiceAreaQueryMatch,
    closestAreas: ServiceAreaQueryMatch[],
    availableZones: BaseZoneWithParentFragment[],
  ) {
    let area = matchedArea && availableZones.find((z) => z.id === matchedArea?.zoneId);
    let areaParent = matchedArea && availableZones.find((z) => z.id === matchedArea?.zoneParentId);

    if ((!matchedArea || matchedArea.matchType === 'distance' || !area || !areaParent) && this.canSetArea()) {

      let description = `This location does not match any areas. Select the most applicable:`;

      if (matchedArea?.matchType === 'distance') {
        description = `This location does not directly match any areas but `
           + `there are some close by. Select the most applicable:`;
      } else if (matchedArea && (!area || !areaParent)) {
        description = `You don't have permission to create jobs in the matched area, where would you like to place it instead?`;
      }

      const selectedZone = await this.openSelectAreaDialog({
        actionRequired: true,
        skipSave: true,
        closestAreas,
        onlyShowCurrentSubzones: false,
        description,
      });

      if (selectedZone?.type !== 'area') { return {}; }
      area = selectedZone;
      areaParent = selectedZone.parent;
    }

    return {
      area,
      areaParent,
    };
  }

  async promptUserToSelectInterstateArea(closestAreas: ServiceAreaQueryMatch[]) {

      const selectedZone = await this.openSelectAreaDialog({
        actionRequired: true,
        skipSave: true,
        closestAreas,
        onlyShowCurrentSubzones: true,
        description: 'What area would you like to place the job in?',
      });

    return {
      area: selectedZone,
      areaParent: selectedZone.parent,
    };
  }

  /**
   * Called when the start location changes. Will determine if the
   * currently contexted zone or the job's zone needs to change.
   *
   * Will ask the user of any large charges before they occur.
   *
   * Sets "areaLoading" while in progress
   *
   * @param areaCode The area code of the start location
   * @returns
   */
  async onStartLocationSet(input: LocationSetInput) {

    const isManual = input.address.startsWith('MANUAL: ');
    if (isManual) {
      input.address = input.address.slice(8);
    }

    this.areaLoading = true;

    // get zone area and available zone data simultaneously
    const [res, { availableZones, currentZone }] = await Promise.all([
      isManual ? undefined : this.getZoneFromAddress(input),
      this.resolveAvailableZones(),
    ]);

    const {
      matchedArea,
      closestAreas,
    } = res || {};

    let area: BaseZoneWithParentFragment;
    let areaParent: BaseZoneWithParentFragment;

    if (this.brandingSvc.currentZone().value?.attributes?.includes('interstate')) {
      const selection = await this.promptUserToSelectInterstateArea(closestAreas || []);
      area = selection.area;
      areaParent = selection.areaParent;
    } else {
      const result = await this.determineAreasFromMatchedAreasAndAvailableZones(matchedArea, closestAreas || [], availableZones);
      area = result.area;
      areaParent = result.areaParent;
    }

    if ((!area || !areaParent) && !this.canSetArea()) {
      this.confirmLocationChangeWihoutAreaChange();
      return;
    } else if (!area || !areaParent) {
      console.error(`${input.address}: could not get area from address.`);
      this.endClear();
      return;
    }

    const jobZone = this.job?.zone;
    const jobInMatchedArea = matchedArea && jobZone?.id === area.id;
    const contextedIntoMatchedParent = currentZone.id === areaParent.id;

    let doRetrieve = false;
    const doSetContext = !contextedIntoMatchedParent;

    if (!this.job) {
      // Zone will be assigned to area after save but will will still set the context
      // into the franchise below. See `this.createJob` below
      this.zoneAssignmentPending = area.id;
    } else if (!this.canSetArea()) {
      this.confirmLocationChangeWihoutAreaChange();
      return;
    } else {
      this.areaLoading = false;
      await this.trySaveJob(true, false, area.id);
      this.bookingRef.jobAreaChanges$.next(area.id);
      this.breakdownRef.jobZoneSet$.next(area.id);
      this.areaLoading = true;
      doRetrieve = true;
    }

    if (doSetContext) {
      await this.plusAuth.setContext(areaParent.id);
    }

    if (this.job && (doRetrieve || doSetContext)) {
      await this.retrieveJob();
    }

    this.areaLoading = false;
    // this.jobRef.updateDistances();
  }

  async onEndLocationSet() {
    await this.trySaveJob();
    // this.jobRef.updateDistances();
  }

  endClear() {
    this.areaLoading = false;
    this.jobRef.locationRefs.find((lr) => lr.type === 'start').setBaseValues(this.job);
    // this.jobRef.updateDistances();
  }

  confirmLocationChangeWihoutAreaChange() {

    // This akward definition is required to properly display message across multiple lines
    const message = 'You can change this job\'s starting location. ' +
      'However, this will not change its area ' +
      'as the job already has charges, transactions or discounts\n\n' +
      'If you are trying to change the area for this job, ' +
      'you will first have to remove any charges, transactions or discounts.\n\n' +
      'Are you sure you want to change the starting location without changing the area?';

    this.confirmService.confirm({
      header: 'This location does not match the job\'s current area',
      message,
      acceptLabel: 'Yes, change starting location',
      acceptIcon: 'pi pi-arrow-right',
      acceptButtonStyleClass: 'p-button-warning',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-times',
      dismissableMask: true,
      accept: async () => {
        this.areaLoading = false;
        await this.trySaveJob(true, false);
        this.areaLoading = true;

        if (this.job) {
          await this.retrieveJob();
        }

        this.areaLoading = false;
        // this.jobRef.updateDistances();
      },
      reject: () => {
        this.endClear();
      }
    });
  }

  canSetArea() {
    const hasTransactions = this.job?.transactions?.length;
    const hasCharges = this.job?.charges?.length;
    const hasDiscounts = (this.job as unknown as EstimatesJobFragment)?.discounts?.length;
    return !(hasTransactions || hasCharges || hasDiscounts);
  }

  confirmationHidden(ev, opts: ZoneConfirmationOptions) {
    if (opts?.onHide) {
      opts.onHide(ev);
    } else if (opts?.reject) {
      opts.reject(ev);
    } else if (opts?.accept) {
      opts.accept(ev);
    }

  }

  /**
   * Get the zone that the job should be placed in based on area code
   */
  async getZoneFromAddress(input: LocationSetInput) {
    let res: ApolloQueryResult<ResolveServiceAreaQuery>;
    try {
      res = await this.resolveServiceAreaGQL.fetch({
        query: input.address,
        areaCode: input.areaCode,
      }).toPromise();
    } catch (err) {

      console.error(err);
      return undefined;
    }


    if (res instanceof Error) {
      console.error(res);

      return undefined;
    }

    return res.data.resolveServiceArea;
  }

  /**
   * Create a new job in the current zone then add the user to it
   *
   * @param stage The stage to create the job at
   * @returns the promise of the newly created job.
   */
  async createJob(stage: 'lead' | 'estimate') {
    const { timeline, timelineDays } = this.customerRef.getTimeline();

    const metadata = {
      jobType: this.customerRef.customerForm.getRawValue().type || undefined,
      jobOrigin: this.customerRef.customerForm.getRawValue().origin || undefined,
    };

    const currentUserId = this.plusAuth.user.id;
    const userId = await this.customerRef.setUser();
    // To prevent customer from from resaving later in save jobs
    this.customerRef.customerForm.markAsPristine();

    // const res = await this.jobService.createJob(input, this.zoneAssignmentPending).toPromise();
    const createJobResult = await firstValueFrom(this.createJobsGQL.mutate({
      stage,
      currency: await this.freyaHelper.getCurrency(),
      timeline,
      timelineDays,
      metadata,
      users: [
        {
          role: JOB_ROLE_MAP.customerRole,
          userId,
        },
        {
          role: JOB_ROLE_MAP.salesAgentRole,
          userId: currentUserId,
        },
      ] as JobUserInput[]
    }, {
      context: {
        zone: this.zoneAssignmentPending,
      },
    }));

    delete this.zoneAssignmentPending;

    // we shouldn't assign whole job here as it causes additional rerender of child components
    // and backend calls. createJob returns only id, so assign it to jobId
    // this.job = res.data.createJob as any;
    this.jobId = createJobResult.data.createJob.id;

    return this.job;
  }

  /* *
   * Update all applicable job properties and relationships.
   * @param saveInfo The info gathered while running the prerequisites in saveJob()
   */
  updateJob(input: UpdateJobInput): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.updateJobs.mutate({ updateJobs: [input] })
        .subscribe((updateRes) => {
          // const [transitioned] = updateRes?.data?.updateJobs?.transitionedJobs;
          // if (transitioned?.newJobId) {
            // this.job.id = transitioned.newJobId;
          // }
          this.detailsHelper.pushUpdate({
            id:this.jobId,
            type:'Jobs',
            action:'update'
          });

          resolve(true);
        }, (err) => {
          reject(err);
        });
    });
  }

  /* * Promote Job to type 'estimate' and move it into the correct zone/area.
   * @returns promise of the newly updated job
   */
  async promoteToEstimate(preventRefetch = false) {
    if (!this.job || this.job.stage !== 'lead') { return; }
    const stage = 'estimate';

    // TODO: set notes?
    // TODO: assign user to job zone if neccessary - maybe do this on the backend.
    // const userId = this.freyaHelper.getJobCustomerId(this.job);

    const { job } = await this.promoteJobService.promote(
      this.job,
      undefined,
      undefined,
      stage,
      preventRefetch
    );
    if (this.job) {
      this.job.stage = stage;
      // this.job.id = transition.newJobId;
    }
  }

  /**
   * Sets the values of controls based on a data object.
   *
   * @param form The FormGroup we are setting the controls for
   * @param info the data object that we are taking the values from
   */
  setFormValues(form, info) {
    for (const key of Object.keys(form.value)) {
      if (info[key] === '' || (!info[key] && info[key] !== 0)) { continue; } // Skip empty values

      const value = info[key] || info[key] === 0 ? info[key] : form.value[key];

      if (value === info[key]) { form.controls[key].markAsDirty(); }

      form.controls[key].setValue(value);
      form.controls[key].markAllAsTouched();
    }
  }

  /**
   * Brings the children up to speed on the jobs, used to update their values without updating the inputs/data
   */
  setJobInChildren() {
    this.customerRef.job = this.job;
    this.breakdownRef.job = this.job;
    this.jobRef.job = this.job;

    //to avoid call listProducts right away when job just created
    //when dealing with new job user first will need to set area manually or add start location
    //to proceed with charges. in both cases we also run jobZoneSet$.next, so products will be available
    if (!this.estimateHelper.newJobCreated) {
      this.breakdownRef.jobZoneSet$.next(this.job.zone?.id);

    }

    //this.inventoryRef.job = this.job;
    //this.inventoryRef.fetchInventory();
    //this triggers listFields query after performing many actions that should not cause retrieving inventory
    //e.g. adding event to job or charges to event. Instead of setting job here we pass it through input
    // and let inventory component fetch inventory onChange

    // No need to set job in confirmation component as its job input is now bound to `this.job`
    // this.confirmationRef.job = this.job;
  }

  openEventDialog() {
    this.eventDialogVisible = true;
  }

  updateModifiedFields(field: ModifiedField) {

    if (!field || !field.name) { return; }

    const existingValue = this.fields.find((f) => f.name === field.name)?.values[0]?.value;

    // If the field value has not changed or was set back to its default value,
    // then do not add it to modified, or remove it if present
    if (existingValue === field.value || field.discardChanges) {
      const removeIndex = this.modifiedFields.findIndex((f) => f.name === field.name);
      if (removeIndex >= 0){
        this.modifiedFields.splice(removeIndex, 1);
      }
      return;
    }

    const index = this.modifiedFields.findIndex((f) => f.name === field.name);
    if (index >= 0) {
      this.modifiedFields.splice(index, 1, field);
    } else if(field.value !== undefined && field.value !== null) {
        this.modifiedFields.push(field);
    }

    this.onEstimateUpdated();
  }
  onEstimateUpdated() {
    if (!this.job?.id) { return; }
    this.updateEstimateTotal();
    this.checkRules();

    // console.log('auto onEstimateUpdated', this.job);
    // only update auto if the job and fields are loaded.
    // this.distanceService.distances.
    // console.log(this.job, fieldsLoaded, this.distanceService.distancesCalculated, this.customerRef.getRequiredEvents());
    if (this.job && this.distanceService.distancesCalculated) {
      // AR_TODO: Replace this funcitonality if necessary
      // this.breakdownRef.updateAuto(this.customerRef.getRequiredEvents());
    }
  }

  updateEstimateTotal() {
    if (this.breakdownRef.hasChanges()) {
      this.estimatedTotal = this.breakdownRef.totalCharges();
    } else if (this.job) {
      this.estimatedTotal = this.job.subTotal;
    } else {
      this.estimatedTotal = 0;
    }
    this.appMain.cd.detectChanges();
  }

  async bookEvent(event: CalendarEvent) {
    this.eventsUpdating = true;
    await this.eventHelper.updateEventStatus(event.id, 'booked');
  }


  async updateEventStatus(event: CalendarEvent) {
    if (this.mutating) {
      return;
    }
    this.mutating = true;
    await this.eventHelper.updateEventStatus(event.id, 'confirmed').catch((err) => {
      console.error(`Error setting event status in estimates component`, err);
    });
    this.mutating = false;
  }

  handleFinancingChange() {
    this.updateModifiedFields({ name: 'misc.needsFinance', value: this.needsFinancing });
  }

  setJobLoading(loading: boolean) {
    this.jobLoading = loading;
    this.estimateHelper.jobLoading.next(this.jobLoading || !!this.jobSaving);
  }

  setJobSaving(status: number) {
    this.jobSaving = status;
    this.estimateHelper.jobLoading.next(this.jobLoading || !!this.jobSaving);
  }

  addRequiredEvent(eventType: EventTypeInfo){
    this.createEventGQL.mutate({
      calendarEvents: [{
        title: eventType.name,
        type: eventType.value,
        jobId: this.job.id,
        status: 'required',
        sequentialOrder: eventType.sequentialOrder,
      }]
    }).subscribe((res) => {
      this.localNotify.success(`${eventType.name} event added`);
      const [event] = res.data.createCalendarEvent.events;
      this.job.events.push(event);
      this.newEventId = event.id;
      this.detailsHelper.pushUpdate({
        action: 'create',
        type: 'Events',
        id: this.job.id,
      });
    }, (err) => {
      console.error(err);
      this.localNotify.error(`Failed to add event ${eventType.name}`, err.message);
    });
  }


  async saveCustomerStep() {
    const customerForm = this.customerRef.customerForm;
    if (!customerForm.valid) return;
  
    try {
      this.setJobLoading(true);
      if (!this.job) {
        await this.createAndConfigureJob(customerForm);
      }
    } catch (error) {
      console.error("An error occurred while creating a job:", error);
    } finally {
      this.setJobLoading(false);
    }
  }

  async createAndConfigureJob(customerForm: UntypedFormGroup) {
    await this.createJob('lead');
    this.estimateHelper.newJobCreated = true;
    this.checkRules();
  
    await this.handleExistingLead(customerForm);
    const promises = [
      this.setHowDidTheyHearAboutUs(customerForm),
      this.fetchJobDetails()
    ];
  
    const [_, jobResponse] = await this.resolveCreateJobPromises(promises);
    await this.processJobResponse(jobResponse as ApolloQueryResult<EstimatingQuery>);
  }

  async handleExistingLead(customerForm: UntypedFormGroup) {
    if (customerForm.controls.existingLead.touched) {
      const existingLeadTagId = await this.customerRef.getTagForLead(customerForm.getRawValue().existingLead);
      await this.addTagsToObject(this.jobId, [existingLeadTagId]);
    }
  }

  async setHowDidTheyHearAboutUs(customerForm: UntypedFormGroup) {
    const { howDidTheyHearAboutUs } = customerForm.controls;
    if (howDidTheyHearAboutUs?.valid && howDidTheyHearAboutUs?.value) {
      const setFieldsInput = {
        fields: {
          fieldName: 'customer.howDidTheyHearAboutUs',
          value: howDidTheyHearAboutUs.value,
        },
        objects: [this.jobId],
        objectLabel: 'Job',
      };
      return lastValueFrom(this.setFieldValuesGQL.mutate(setFieldsInput));
    }
  }

  async fetchJobDetails() {
    return lastValueFrom(this.estimatingGQL.fetch(this.getJobQueryInput()));
  }

  async resolveCreateJobPromises(
    promises: (Promise<MutationResult<SetFieldValuesMutation>> | Promise<ApolloQueryResult<EstimatingQuery>>)[]
  ) {
    const results = await Promise.all(promises.filter(Boolean));
    // Assuming the job response is always the last promise resolved
    return results;
  }

  async processJobResponse(
    jobResponse: ApolloQueryResult<EstimatingQuery>
  ) {
    this.localNotify.success('Job Saved');

    /*
      Mark howDidTheyHearAboutUs as pristine because, while updating a job, we save these fields by collecting them into 
      modifiedFields. An observer is listening to these changes. Hence, after creating a new job and changing the URL, we get a 
      popup saying "You have unsaved changes" because howDidTheyHearAboutUs is marked as dirty.
    */
    this.customerRef.customerForm.controls.howDidTheyHearAboutUs.markAsPristine();
    this.modifiedFields = [];

    if (!jobResponse?.data || this.zoneChanging) return;
  
    const [job] = cloneDeep(jobResponse.data.jobs.jobs);
    if (!job) return;
  
    this.job = job as EstimatesJobFragment;
    await this.setPageUrl();
  }

  async saveJobManually () {
    // Detect if we are on the customer step and the job is not created.
    if(!this.jobId && ['customer', '0'].includes(this.route.snapshot.queryParams.step)){
      await this.saveCustomerStep();
    }else{
      this.trySaveJob(false, true);
    }
  }
}
