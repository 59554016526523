import { Pipe, PipeTransform } from '@angular/core';

import { BaseJobFragment } from '../../../generated/graphql.generated';

@Pipe({
  name: 'jobTimeline',
  standalone: true,
})
export class JobTimelinePipe implements PipeTransform {

  transform(j: BaseJobFragment): string {

    // TODO: determine timeline from events

    let str = j.timeline;
    if (!j.timeline) {
      return 'Not set';
    }

    if (j.timelineDays === 0 || j.timelineDays === 1) {
      str += ` (Not flexible)`;
    } else if (j.timelineDays > 1) {
      str += ` (${ j.timelineDays } days of flexibility)`;
    }

    return str;
  }

}
