import { cloneDeep } from 'lodash';

import Quill from 'quill';
import { BaseCommentFragment, CommentWithRepliesFragment, UpdateCommentPartial } from '../../../generated/graphql.generated';

export const commentDefaultToolbar = [
	['bold', 'italic', 'underline', 'strike'],        // toggled buttons
	// ['blockquote', 'code-block'],

	// [{ 'header': 1 }, { 'header': 2 }],               // custom button values
	[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': "check" }],
	// [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
	// [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
	// [{ 'direction': 'rtl' }],                         // text direction

	// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
	// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

	[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
	// [{ 'font': [] }],
	// [{ 'align': [] }],

	// ['clean'],                                         // remove formatting button

	// ['link', 'image', 'video']                         // link and image, video
];

export function removeCommentFromArray(
	_comments: CommentWithRepliesFragment[],
	commentId: string,
) {
	const {
		rootIndex,
		parent,
		parentIndex,
		comment,
	} = findComment(
		_comments,
		commentId,
	);

	const comments = [ ..._comments ];
	if (parent) {
		const parentClone = cloneDeep(parent);
		parentClone.replies.splice(parentIndex, 1);
		comments[rootIndex] = parentClone;
	} else if (rootIndex >= 0) {
		comments.splice(rootIndex, 1);
	}

	return {
		comments,
		rootIndex,
		parent,
		parentIndex,
		comment,
	};

}

/**
 * Finds a comment in a nested array
 * and returns the comment, index, and parent information if applicable
 * @param comments 
 * @param commentId 
 * @returns 
 */
export function findComment(
	comments: CommentWithRepliesFragment[],
	commentId: string,
) {
	let rootIndex = comments.findIndex((c) => c.id === commentId);

	// we found a root level comment by id
	if (rootIndex >= 0) {
		const comment = comments[rootIndex];
		return {
			comment,
			rootIndex,
		};
	}

	// Find the parent (at root) that contains this comment since
	// comments are only ever one level deep
	for (rootIndex = 0; rootIndex < comments.length; rootIndex++) {
		const parent = comments[rootIndex];
		const parentIndex = parent.replies?.findIndex((c) => c.id === commentId);
		if (parentIndex >= 0) {
			const comment = parent.replies[parentIndex];
			return {
				comment,
				rootIndex,
		
				parent,
				parentIndex,
			};
		}
	}

	return {};
}

/**
 * Takes a list of existing comments,
 * finds that comment by ID, and replaces it
 * with the "replacement" comment.
 * 
 * Optionally, setting "partial" to true will only update
 * the fields specified in the replacement comment
 * and not the whole comment
 * @param initialComments (not changed)
 * @param id 
 * @param replacementComment 
 * @param partial 
 * @returns an object with the following properties:
 * 
 * comments - an updated version of "initialComments"
 * with replacement comment updating the list of comments
 * 
 * comment - the comment that was updated (undefined if
 * the comment is not found in initialComments)
 * 
 * rootIndex - the index of the parent comment/thread in the root level
 * list of initialComments
 *
 * parent - the threads parent comment
 * (if this is not a top level comment, otherwise undefined)
 * 
 * parentIndex - the index of this comment in the parent comment
 * (if this is not a toplevel comment, otherwise undefined)
 * 
 */
export function updateCommentInPlace(
	initialComments: CommentWithRepliesFragment[],
	id: string,
	replacementComment: CommentWithRepliesFragment | UpdateCommentPartial,
	partial = false,
) {

	let comments = [ ...initialComments ];

	const {
		comment,
		rootIndex,
		parent,
		parentIndex,
	} = findComment(comments, id);

	let fullReplacementComment = replacementComment as CommentWithRepliesFragment;
	if (partial) {
		fullReplacementComment = {
			...comment,
			...replacementComment,
		};
	}

	if (comment && parent) {
		const parentClone = cloneDeep(parent);
		parentClone.replies[parentIndex] = fullReplacementComment;
		comments[rootIndex] = parentClone;
		parentClone.replies = sortComments(parentClone.replies, true);
	} else if (comment) {
		comments[rootIndex] = fullReplacementComment;
		comments = sortComments(comments, false);
	}

	return {
		comments,
		comment,
		rootIndex,
		parent,
		parentIndex,
	};
}

export function sortComments<C extends BaseCommentFragment>(
	_comments: C[],
	ascending = false,
) {
	const comments = [ ..._comments ]
		.sort((a, b) => (a.createdAt - b.createdAt) * (ascending ? 1 : -1));

	return comments;
}

export function sortCommentsWithReplies<C extends CommentWithRepliesFragment>(
	_comments: C[],
	ascending = false,
) {
	const comments = sortComments(_comments, ascending);
	return comments.map((_parentComment) => {
		const parentComment = cloneDeep(_parentComment);

		// replies are always sorted ascending
		parentComment.replies = sortComments(parentComment.replies, true);

		return parentComment
	});
}

/**
 * Adds a comment to a list of initial comments,
 * adding it to a thread if the thread already exists.
 * 
 * You should check that the comment does not already exist on this list of comments
 * before calling this function. Use "updateCommentInPlace" or "findComment" to do this.
 * @param initialComments 
 * @param newComment 
 * @returns an object with the updated list of comments and the comment that was added
 */
export function addCommentToComments(
	initialComments: CommentWithRepliesFragment[],
	newComment: CommentWithRepliesFragment,
) {
	let comments = [ ...initialComments ];
	const parentIndex = comments.findIndex((c) => newComment?.thread?.id && c.thread?.id === newComment.thread?.id);

	if (parentIndex >= 0) {
		const parent = cloneDeep(comments[parentIndex]);
		parent.replies = parent.replies || [];
		parent.replies.push(newComment);
		parent.replies = sortComments(parent.replies, true);
		comments.splice(parentIndex, 1, parent);
	} else {
		comments = sortComments([
			newComment,
			...comments,
		], false);
	}

	return {
		comments,
		newComment,
		parentIndex,
	}
}

export function isQuillEmpty(
	quill: Quill,
) {
	if (!quill) { return true; }
	const contents = quill.getContents();
	if (!contents?.ops?.length) { return true; }
	if (contents.ops.length > 1) { return false; }

	const op = contents.ops[0];
	if (typeof op.insert === 'string' && op.insert.trim() === '') {
		return true;
	}

	return false;
}
