
<app-job-add-comment
	[jobId]="jobId$ | async"
></app-job-add-comment>

<div class="comments">
	<app-job-comment
		*ngFor="let comment of (comments$ | async); trackBy:identifyComment"
		class="comment"
		[class.internal]="!comment.author"
		[class.topLevel]="true"
		[comment]="comment"
		[job]="job$ | async"
	>

	</app-job-comment>
</div>