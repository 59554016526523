import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigService } from '@karve.it/core';
import { ZoneInput } from '@karve.it/interfaces/zones';
import { cloneDeep } from 'lodash';
import { catchError, forkJoin, lastValueFrom, of } from 'rxjs';
import { CANADA, countries, regex, USA } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { Timezone, TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { BaseZoneWithAreasFragment, CreateLocationsGQL, CreateLocationsMutationVariables, CreateZonesGQL, ListZonesWithAreasGQL, LocationCreateBase, LocationsGQL, UpdateZonesGQL, UpdateZonesMutationVariables } from '../../../generated/graphql.generated';
import { BrandingService } from '../../services/branding.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { GoogleHelperService } from '../../services/google-helper.service';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

const dockLocationIdConfigKey = 'franchise-info.dockLocationId';
const timezoneConfigKey = 'system-details.timezone';

@Component({
  selector: 'app-mutate-area',
  templateUrl: './mutate-area.component.html',
  styleUrls: ['./mutate-area.component.scss']
})
export class MutateAreaComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('areaCodes') codesRef: TemplateRef<any>;
  @ViewChild('color') colorRef: TemplateRef<any>;
  @ViewChild('country') countryRef: TemplateRef<any>;
  @ViewChild('description') descriptionRef: TemplateRef<any>;
  @ViewChild('dockAddress') dockAddressRef: TemplateRef<any>;
  @ViewChild('timezone') timezoneRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() area: BaseZoneWithAreasFragment;

  zoneQueryRef: ReturnType<typeof this.listZonesWithAreasGQL.watch>;

  steps: MutateObjectElement[];

  country: string;
  timezone: string;

  subs = new SubSink();

  currentZoneId: string;
  dockSetForZoneId: string;


  showColorTags = true;

  areaForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    areaCodes: new UntypedFormControl([], []),
    color: new UntypedFormControl('#81D4FA', []),
    country: new UntypedFormControl(environment.defaultCountry, [Validators.required]),
    description: new UntypedFormControl('', []),
    dockAddress: new UntypedFormControl('', []),
    timezone: new UntypedFormControl('', []),
  });
  areaFormValues = this.areaForm.value;

  countries = countries;
  timezones: Timezone[] = [];

  locationId: string | undefined;
  dockLocationObject: google.maps.places.PlaceResult;

  constructor(
    private localNotify: FreyaNotificationsService,
    private createZonesGQL: CreateZonesGQL,
    private updateZonesGQL: UpdateZonesGQL,
    private listZonesWithAreasGQL: ListZonesWithAreasGQL,
    private createLocationsGQL: CreateLocationsGQL,
    private getLocationsGQL: LocationsGQL,
    private configService: ConfigService,
    private detailHelper: DetailsHelperService,
    private cd: ChangeDetectorRef,
    private brandingSvc: BrandingService,
    private freyaHelper: FreyaHelperService,
    public googleHelper: GoogleHelperService,
    public timezoneHelper: TimezoneHelperService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.brandingSvc.currentZone().subscribe(zone => {
      this.currentZoneId = zone?.id;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  openDialog() {
    this.resetGoogleLocation();

    if (this.mutateType === 'create') {
      this.areaForm.reset(this.areaFormValues);
      this.retrieveCountry();
      this.retrieveTimezones();
    } else if (this.mutateType === 'update') {
      this.assignDefaultValues();
      this.retrieveCountry();
      this.retrieveTimezones(this.area?.id);
      this.retrieveAreaDockId();
    }

    this.steps = [
      { name: 'Name', ref: this.nameRef, control: 'name', type: 'text' },
      { name: 'Description', ref: this.descriptionRef, control: 'description', type: 'text' },
      { name: 'Area Codes', ref: this.codesRef, control: 'areaCodes', type: 'array' },
      { name: 'Color', ref: this.colorRef, control: 'color', type: 'text' },
      { name: 'Dock Address', ref: this.dockAddressRef, control: 'dockAddress', type: 'text' },
      { name: 'Timezone', ref: this.timezoneRef, control: 'timezone', type: 'text' },
    ];

    // If the country isn't set make sure they have the option
    if (!this.country) {
      this.steps.push({ name: 'Country', ref: this.countryRef, control: 'country', type: 'text' });
    }

    this.mutateRef.steps = this.steps;
    this.mutateRef.mutateType = this.mutateType;

    this.mutateRef.openDialog();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createArea();
    } else if (this.mutateType === 'update') {

      if (!this.areaForm.dirty) {
        this.localNotify.success('Area already up to date');
        this.mutateRef.closeDialog();
        return;
      }

      this.editZone();


    }
  }

  createArea() {
    const createZoneInput = {
      attributes: ['area'],
      name: this.areaForm.controls.name.value,
      type: 'area',
      color: this.areaForm.controls.color.value,
      description: this.areaForm.controls.description.value,
      contextable: true,
    } as ZoneInput;

    this.subs.sink = this.createZonesGQL.mutate({
      parent: this.brandingSvc.currentZone().value.id,
      zones: [createZoneInput],
    }).subscribe((res) => {
      if (this.areaForm.controls.areaCodes.value.length > 0) {
        const locations = this.areaForm.controls.areaCodes.value.map((code) => (
          {
            areaCode: code,
            attributes: ['ZoneArea', this.areaForm.controls.name.value],
            public: false,
            name: `${code} - ${this.areaForm.controls.name.value}`,
            addressLineOne: code,
            country: this.areaForm.controls.country.value,
          }
        ));

        this.createLocationsGQL.mutate({ input: { locations } }, {
          context: {
            zone: res.data.createZones[0].id,
          },
        }).subscribe(async (createRes) => {

          if (res.data.createZones[0].id) {
            const configInput = await this.buildConfigInput();
            await lastValueFrom(this.updateConfigValues(configInput));
          }

          this.detailHelper.pushUpdate({
            id: res.data.createZones[0].id,
            type: 'Zones',
            action: 'create',
          });
          this.mutateRef.closeDialog();
          this.localNotify.addToast.next({ severity: 'success', summary: 'Area created' });

        }, (createErr) => {
          this.mutateRef.loading = false;
          this.localNotify.apolloError(`Failed to add codes to area`, createErr);
        });

      } else {
        this.mutateRef.closeDialog();
        this.localNotify.addToast.next({ severity: 'success', summary: 'Area created' });
        this.detailHelper.pushUpdate({
          id: res.data.createZones[0].id,
          type: 'Zones',
          action: 'create'
        });

      }
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to create area`, err);
    });
  }

  async retrieveCountry() {

    const country = await this.freyaHelper.getCountry();

    this.country = country.country;
    this.areaForm.controls.country.setValue(this.country);

  }

  async retrieveTimezones(areaId?: string) {

    this.timezones = this.timezoneHelper.availableTimezones;

    if (areaId) {
      this.configService.getConfigValues(
        {
          keys: [timezoneConfigKey],
        },
        areaId
      ).subscribe((res) => {
        const timezone = res.data.getConfigValues[0].value;
        this.timezone = timezone;
        this.areaForm.controls.timezone.setValue(this.timezone);
      });
      this.areaForm.controls.timezone.markAsUntouched();
      this.areaForm.controls.timezone.markAsPristine();
    }
  }

  async retrieveAreaDockId() {
    this.configService.getConfigValues(
      {
        keys: [dockLocationIdConfigKey],
      },
      this.area.id
    ).subscribe((res) => {
      const id = res.data.getConfigValues[0].value;
      this.dockSetForZoneId = res.data.getConfigValues[0].zone;
      this.retrieveLocationAddress(id);
    });
  }

  async retrieveLocationAddress(id: string) {
    this.getLocationsGQL.fetch({
      filter: {
        ids: [id]
      }
    }).subscribe((res) => {
      const result = res.data.locations?.locations[0]?.addressLineOne;
      this.areaForm.controls.dockAddress.setValue(result);
      this.areaForm.controls.timezone.markAsUntouched();
      this.areaForm.controls.timezone.markAsPristine();
    });
  }

  retrieveZone() {
    if (!this.zoneQueryRef) {
      this.zoneQueryRef = this.listZonesWithAreasGQL.watch({ filter: { ids: [this.area.id] } }, { fetchPolicy: 'network-only' });
    } else {
      this.zoneQueryRef.refetch({ filter: { ids: [this.area.id] } });
    }

    this.subs.sink = this.zoneQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.area = res.data.zones.nodes[0];
        this.detailHelper.detailsItem.next({ type: 'area', item: this.area });
      }
    });
  }

  assignDefaultValues() {
    this.areaForm.setValue({
      name: this.area.name,
      areaCodes: this.area.areas.map((l) => l.areaCode),
      color: this.area.color,
      country: this.country ? this.country : environment.defaultCountry,
      description: this.area.description,
      dockAddress: '',
      timezone: this.timezone ? this.timezone : '',
    });
  }

  // Edit the Zone Properties
  async editZone() {
    const editZoneInput = this.buildEditZoneInput();

    const configInput = await this.buildConfigInput();

    if (Object.keys(editZoneInput.edit).length === 0 && configInput.configs.length === 0) {
      this.mutateRef.closeDialog();
      return this.localNotify.success('Area already up to date');
    }

    const configUpdate$ = this.updateConfigValues(configInput);

    const zoneUpdate$ = this.updateZone(editZoneInput);

    forkJoin([configUpdate$, zoneUpdate$]).subscribe({
      next: () => {
        this.mutateRef.closeDialog();
        this.localNotify.success('Area updated');
        this.retrieveZone();
      },
      error: (err) => {
        this.mutateRef.closeDialog();
        this.localNotify.apolloError('Failed to update area', err);
      }
    });
  }

  private updateZone(editZoneInput: UpdateZonesMutationVariables) {

    if (Object.keys(editZoneInput.edit).length === 0) {
      return of(undefined); // No updates needed
    }

    return this.updateZonesGQL.mutate(editZoneInput).pipe(
      catchError((err) => {
        this.localNotify.apolloError('Failed to update zone', err);
        return of(undefined); // Continue with forkJoin
      })
    );
  }

  private updateConfigValues(configInput: { configs: any[] }) {

    if (configInput.configs.length === 0) {
      return of(undefined); // No updates needed
    }

    return this.configService.setConfigValues(configInput, this.area?.id).pipe(
      catchError((err) => {
        this.localNotify.apolloError('Failed to update configs for the area', err);
        return of(undefined); // Continue with forkJoin
      })
    )

  }

  private buildEditZoneInput(): UpdateZonesMutationVariables {
    const editZoneInput: UpdateZonesMutationVariables = {
      ids: [this.area.id],
      edit: {},
    };

    const { name, color, description, areaCodes, country } = this.areaForm.controls;
    const formValue = this.areaForm.value;

    // Only add properties to edit if they have changed
    if (name.dirty) {
      editZoneInput.edit.name = formValue.name;
    }

    if (color.dirty) {
      editZoneInput.edit.color = formValue.color;
    }

    if (description.dirty) {
      editZoneInput.edit.description = formValue.description;
    }

    if (areaCodes.dirty) {
      const locationsToAdd = formValue.areaCodes
        .filter((ac) => !this.area.areas.find((a) => a.areaCode === ac));

      editZoneInput.edit.addAreaCodes = {
        areaCodes: locationsToAdd,
        country: country.value,
      };

      const locationsToRemove = this.area.areas
        .filter((a) => !formValue.areaCodes.find((ac) => ac === a.areaCode))
        .map((l) => l.id);

      editZoneInput.edit.removeAreaCodes = locationsToRemove;
    }

    if (Object.keys(editZoneInput.edit).length) {
      editZoneInput.edit.attributes = this.area.attributes;
    }

    return editZoneInput;
  }

  private async buildConfigInput(): Promise<{ configs: any[] }> {
    const configInput = { configs: [] };
    const { timezone, dockAddress } = this.areaForm.controls;

    if (timezone.dirty) {
      configInput.configs.push({
        key: timezoneConfigKey,
        value: this.areaForm.controls.timezone.value,
      });
    }

    if (dockAddress.dirty && this.dockLocationObject) {
      const locationInput = this.googleHelper.convertGoogleLocationToCreateLocationInput(this.dockLocationObject);
      const areaCode = locationInput.areaCode.replace(' ', '');

      const locationWithFormattedAreaCode = cloneDeep(locationInput);
      locationWithFormattedAreaCode.areaCode = areaCode;

      this.locationId = await this.createLocation(locationWithFormattedAreaCode);

      if (this.locationId) {
        configInput.configs.push({
          key: dockLocationIdConfigKey,
          value: this.locationId,
        });
      }
    }

    return configInput;
  }


  async handleDockAddressChange(address: google.maps.places.PlaceResult) {
    if (!this.googleHelper.isValidGooglePlacesAddress(address)) {
      return;
    }

    this.areaForm.controls.dockAddress.setValue(address.formatted_address);
    this.dockLocationObject = address;
  }

  setDockAddressConfig(locationId: string, areaId: string) {

    const configInput = {
      configs: [
        {
          key: dockLocationIdConfigKey,
          value: locationId,
        }],
    }

    this.configService.setConfigValues(configInput, areaId).subscribe((res) => {
      this.localNotify.addToast.next({ severity: 'success', summary: 'Dock location for area updated' });
    }, (err) => {
      this.localNotify.apolloError(`Dock update for area failed`, err);
    }
    );
  }

  async createLocation(locationWithFormattedAreaCode: LocationCreateBase) {

    const createLocationsInput: CreateLocationsMutationVariables['input'] = {
      locations: [{
        public: false,
        ...locationWithFormattedAreaCode,
      }]
    }

    const res = await this.createLocationsGQL.mutate({
      input: createLocationsInput,
    }).toPromise();

    return res?.data?.createLocations?.locations[0]?.id;
  }

  setTimeZoneConfig(areaId: string) {

    const configInput = {
      configs: [
        {
          key: timezoneConfigKey,
          value: this.areaForm.controls.timezone.value,
        }],
    }

    this.configService.setConfigValues(configInput, areaId).subscribe((res) => {
      this.localNotify.addToast.next({ severity: 'success', summary: 'Timezone for area updated' });
    }, (err) => {
      this.localNotify.apolloError(`Timezone update for area failed`, err);
    }
    );
  }

  resetConfigToZoneDefault(configKey: string, areaId: string) {
    const configInput = {
      configs: [
        {
          key: configKey,
          reset: true,
          value: '',
        }],
    }

    this.configService.setConfigValues(configInput, areaId).subscribe((res) => {
      this.localNotify.addToast.next({ severity: 'success', summary: 'Reset to default franchise value' });

      if (configKey === dockLocationIdConfigKey) {
        this.areaForm.controls.dockAddress.setValue('');
        this.areaForm.controls.timezone.markAsUntouched();
        this.areaForm.controls.timezone.markAsPristine();
      } else if (configKey === timezoneConfigKey) {
        this.areaForm.controls.timezone.setValue('');
        this.areaForm.controls.timezone.markAsUntouched();
        this.areaForm.controls.timezone.markAsPristine();
      }
    }, (err) => {
      this.localNotify.apolloError(`Dock update for area failed`, err);
    }
    );
  }

  validateAreaCode(event) {
    const target = event.originalEvent.target;
    const value = event.value.replace(/[\-" "]/g, '');

    let codeValid: boolean;
    if (this.areaForm.value.country === CANADA) {
      const re = new RegExp(regex.areaCodeCanada);
      codeValid = re.test(value);
    } else if (this.areaForm.value.country === USA) {
      const re = new RegExp(regex.areaCodeUSA);
      codeValid = re.test(value);
    }

    // Remove the value from being automatically added to the control
    const valueAfterRemove = this.areaForm.controls.areaCodes.value.filter((ac) => ac !== event.value);
    const control = this.areaForm.controls.areaCodes;
    if (!codeValid) {
      setTimeout(() => {
        // target.value = value;
        control.setErrors({ pattern: true });
      }, 100);
    } else {
      // control.setValue([...control.value, value]);
    }
  }

  capitalizeText(input) {
    input.value = input.value.toUpperCase();
  }

  clearAreaInput(event) {
    event.target.value = '';
    this.areaForm.controls.areaCodes.setErrors(null);
  }

  changeTagColors() {
    this.showColorTags = false;
    setTimeout(() => {
      this.showColorTags = true;
    }, 0.01);
  }

  private resetGoogleLocation() {
    this.dockLocationObject = null;
    this.locationId = undefined;
  }
}
