import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { clone } from 'lodash';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { PermissionService } from 'src/app/services/permission.service';
import { parseMenuItemCategoriesVisible } from 'src/app/utilities/menu-item.util';
import { SubSink } from 'subsink';

import { BaseZoneWithAreasFragment, GetConfigValuesGQL, ListZonesWithAreasGQL, ListZonesWithAreasQueryVariables, LocationsGQL } from '../../../../generated/graphql.generated';
import { BrandingService } from 'src/app/services/branding.service';
import { ConfigService } from '@karve.it/core';

const dockLocationIdConfigKey = 'franchise-info.dockLocationId';

@Component({
  selector: 'app-area-details',
  templateUrl: './area-details.component.html',
  styleUrls: ['./area-details.component.scss']
})
export class AreaDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() area: BaseZoneWithAreasFragment;
  loading = false;

  updateAreaAction = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'update',
        objectType: 'area',
        object: this.area
      });
    }
  };

  areaActions = [{
    label: 'Area Actions',
    items: [
      this.updateAreaAction,
    ],
    visible: false,
  }];

  showDeleteDialog = false;

  currentZoneId: string;
  dockSetForZoneId: string;
  dockAddressForArea: string;

  watchQuery: ReturnType<typeof this.listZonesWithAreasGQL['watch']>;

  private subs = new SubSink();

  constructor(
    private permissionHandler: PermissionService,
    private freyaMutate: FreyaMutateService,
    private listZonesWithAreasGQL: ListZonesWithAreasGQL,
    private configService: ConfigService,
    private getLocationsGQL: LocationsGQL,
    private brandingSvc: BrandingService,
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.brandingSvc.currentZone().subscribe(zone => {
      this.currentZoneId = zone?.id;
    });

    this.initializePermissions();
    if (!this.area?.areas) {
      this.fetch();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    delete this.watchQuery;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.area) {
      this.fetch();
    }

    if (changes?.area?.currentValue?.id !== changes?.area?.previousValue?.id) {
      this.configService.getConfigValues(
        {
          keys: [dockLocationIdConfigKey],
        },
        this.area.id
      ).subscribe((res) => {
        const id = res.data.getConfigValues[0].value;
        this.dockSetForZoneId = res.data.getConfigValues[0].zone;
        if (this.dockSetForZoneId !== this.currentZoneId) {
          this.retrieveLocationAddress(id);
        } else {
          this.dockAddressForArea = '';
        }
      });
    }
  }

  retrieveLocationAddress(id: string) {
    this.getLocationsGQL.fetch({
      filter: {
        ids: [id]
      }}).subscribe((res) => {
        this.dockAddressForArea = res.data.locations?.locations[0]?.addressLineOne;
      });
  }


  fetch() {
    if(!this.area.id) { return; }

    if (this.watchQuery) {
      return this.watchQuery.setVariables(this.getVariables());
    }

    this.watchQuery = this.listZonesWithAreasGQL.watch(this.getVariables(), {
      fetchPolicy: 'cache-first',
    });
    this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
      this.loading = res.loading;
      const [area] = res.data?.zones?.nodes || [];
      if (area) {
        this.area = area;
      }
    });
  }

  getVariables(): ListZonesWithAreasQueryVariables {
    return {
      filter: {
        ids: [ this.area.id ],
      }
    };
  }


  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['zones.update'])
      .subscribe((res) => {
        this.updateAreaAction.visible = res[0];

        const [ actions ] = this.areaActions;

        actions.visible = actions.items.some((i) => i.visible);

        parseMenuItemCategoriesVisible(this.areaActions);
        this.areaActions = clone(this.areaActions);
      });
  }

  // deleteArea() {
  //   this.zoneService.removeZones({hardDelete: false, ids: [this.area.id]}).subscribe((res) => {
  //     this.detailsHelper.zonesUpdated.next(true);
  //     this.detailsHelper.detailsItem.next(null);
  //     this.localNotify.addToast.next({severity: 'success', summary: 'Area deleted'});
  //   }, (err) => {
  //     this.localNotify.addToast.next({severity: 'danger', summary: 'Failed to delete area'});
  //   });
  // }

}
