import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { Asset, CalendarEventForScheduleFragment, Role, User } from "../../../../generated/graphql.generated";

import { CrewRoleOptions, EventStatuses, UpdateCrewActionPayload, DispatchSortOptions } from "./dispatch.reducer";

export const DispatchActions = createActionGroup({
	source: "Dispatch",
	events: {
		"Component Hard Refreshed": emptyProps(),

		// Init
		"Init Crew": emptyProps(),

		// List events actions
		"Hard Refresh Events": props<{
			date: Date | null;
		}>(),
		"Events Loading": emptyProps(),
		"Events Loaded": props<{
			events: CalendarEventForScheduleFragment[];
		}>(),
		"Events Load Error": props<{
			error: Error;
		}>(),

		// List events filters
		"Set Dispatch Date": props<{
			date: Date;
		}>(),
		"Set Event Status": props<{
			eventStatus: EventStatuses;
		}>(),
		// Local sort
		"Set Sort By": props<{
			sortBy: DispatchSortOptions;
		}>(),

		// List users
		"Set User Search": props<{
			search: string;
		}>(),
		"Set User Role": props<{
			role: CrewRoleOptions;
		}>(),
		"Users Loading": emptyProps(),
		"Users Loaded": props<{
			users: User[];
		}>(),
		"Users Load Error": props<{
			error: Error;
		}>(),


		// List Roles
		"Roles Loading": emptyProps(),
		"Roles Loaded": props<{
			roles: Role[];
		}>(),
		"Roles Loaded On Component Hard Refresh": props<{
			roles: Role[];
		}>(),
		"Roles Load Error": props<{
			error: Error;
		}>(),


		// List Assets
		"Assets Loading": emptyProps(),
		"Assets Loaded": props<{
			assets: Asset[];
		}>(),
		"Assets Load Error": props<{
			error: Error;
		}>(),



		// Assign Crew Member
		"Update Crew": props<{
			edits: UpdateCrewActionPayload[]
		}>(),
		"Update Crew Success": props<{
			edits: UpdateCrewActionPayload[]
		}>(),
		"Update Crew Error": props<{
			error: Error,
			edits: UpdateCrewActionPayload[]
		}>(),
	},
});
