
<section *ngIf="loaded$ | async" class="customer">
	<h3>Customer</h3>

	<div>
		<a>
			{{ (customerName$ | async) }}
		</a>
		<span> | </span>
		<a href="mailto:{{(customer$ | async).email}}">
			{{ (customer$ | async).email }}
		</a>
		<span> | </span>
		<a href="tel:{{(customer$ | async).phone}}">
			{{ (customer$ | async).phone }}
		</a>
	</div>
	<div>
		<span>Source: </span>
		<a>
			{{ (fields$ | async).source }}
		</a>
	</div>
	<div>
		<span>Timeline: </span>
		<a>
			{{ (job$ | async) | jobTimeline }}
		</a>
	</div>
	<!-- Secondary phone and other fields go here -->

</section>

<!-- <section  *ngIf="state.ready" class="description">
	<app-job-description></app-job-description>
</section> -->

<section  *ngIf="loaded$ | async" class="locations">
	<h3>Locations</h3>

	<app-location-accordion></app-location-accordion>

	<div class="travel-info">
		<div class="travel-times">
			<!-- Click route to open in new tab -->
			<span>
				Start -> End: <u>8.41km / 14min</u>
			</span>
			<span>
				Dock -> Start -> End -> Dock: <u>16.60km / 32min</u>
			</span>
		</div>
		<div class="travel-actions">
			<p-button
				label="{{ showMap ? 'Hide' : 'Show' }} Map"
				class="p-button-lg"
				(click)="showMap = !showMap"
			></p-button>

		</div>
	</div>
	<app-travel-map
		*ngIf="showMap"
	></app-travel-map>
</section>

<section *ngIf="loaded$ | async" class="events">
	<h3>Timeline - {{ (job$ | async) | jobTimeline }}</h3>

	<ul>
		<li *ngFor="let event of (events$ | async)">
			<span>
				{{ event.start * 1000 | date : 'short' }}
				 - 
				{{ event.title }} ({{ event.status }})
			</span>

		</li>


	</ul>
</section>
