import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Delta } from 'quill/core';

import { AddCommentInput, BaseCommentFragment, BaseJobUserFragment, BaseUserFragment, FullJobFragment } from '../../../../generated/graphql.generated';
import { PlusAuthenticationService } from '../../../core/public-api';
import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../../services/details-helper.service';
import { SharedModule } from '../../../shared/shared.module';
import { CallState, generateUUID, isErrorState } from '../../../utilities/state.util';
import { JobToolActions } from '../../job-tool.actions';
import { JobToolComment, jobToolFeature } from '../../job-tool.reducer';
import { commentDefaultToolbar, isQuillEmpty } from '../comments.utils';

export type CommentMode = 'view' | 'edit';

@Component({
  selector: 'app-job-comment',
  standalone: true,
  imports: [
    CommonModule,
    QuillEditorComponent,
    FormsModule,
    FreyaCommonModule,
    SharedModule,
  ],
  templateUrl: './job-comment.component.html',
  styleUrl: './job-comment.component.scss'
})
export class JobCommentComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() job: FullJobFragment;
  @Input() comment: (BaseCommentFragment | JobToolComment) & {
    callState?: CallState;
  };
  @Input() replyEnabled = true;
  @Input() showReplies = true;
  @Input() isReply = false;
  @Input() repliesExpanded = true;

  @Input() parent: JobCommentComponent;

  @ViewChild('editor', { static: false }) editor: QuillEditorComponent | undefined
  @ViewChild('replyEditor', { static: false }) replyEditor: QuillEditorComponent | undefined

  mutating$ = this.store.select(jobToolFeature.addCommentLoading);

  editModules: QuillModules = {
    toolbar: commentDefaultToolbar,
    keyboard: {
      bindings: {
        submit: {
          key: 'Enter',
          ctrlKey: true,
          handler: (range, context) => {
            this.addReply();
          },
        },
      },
    },
  };
  viewModules: QuillModules = {
    toolbar: false,
  };

  contents: Delta | string;
  replyContents: Delta;

  format = 'text';

  mode: CommentMode = 'view';

  canEdit = false;
  canDelete = false;
  showMenu = false;

  replyOpened = false;

  original: any;
  authorJobRoles: BaseJobUserFragment[] = [];

  commentMenuItems: MenuItem[] = [
    {
      label: 'Delete Comment',
      styleClass: 'delete-comment',
      command: ($event) => {
        // console.log($event);
        this.confirmService.confirm({
          header: 'Are you sure you want to delete this comment?',
          message: 'This action cannot be undone.',
          rejectButtonStyleClass: 'p-button-outlined',
          acceptButtonStyleClass: 'p-button-danger',
          rejectLabel: 'Cancel',
          acceptLabel: 'Delete Comment',
          accept: () => {
            this.store.dispatch(JobToolActions.deleteComment({
              comment: this.comment,
            }));
          },
        })

      }
    }
  ];

  isErrorState = isErrorState;

  get replies() {
    if (!('replies' in this.comment)) {
      return [];
    }

    return this.comment.replies;
  }

  constructor(
    private detailsHelper: DetailsHelperService,
    private store: Store,
    private confirmService: ConfirmationService,
    private auth: PlusAuthenticationService,
  ) {}

  ngOnInit(): void {

    this.setMode('view');
    // console.log(this.contents);
  }
  

  ngAfterViewInit(): void {
    // this.editor?.quillEditor?.setContents(this.contents.ops)
    // console.log(this.comment.contents, this.editor?.quillEditor);
    // console.log(this.contents, this.editor);
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.comment) {
        this.initComment();
      }

      this.authorJobRoles = this.findAuthorJobRoles(this.comment.author);
      // console.log(this.authorJobRoles, this.comment.author, this.job?.users);
  }

  initComment() {
    this.format = 'text';
    if (this.comment?.contents) {
      this.contents = JSON.parse(this.comment.contents);
      this.format = 'object';
    } else {
      this.contents = this.comment.text;
    }

    if (!this.comment.thread) {
      this.replyEnabled = false;
    }

    this.canEdit = this.isOwnComment();
    this.canDelete = this.isOwnComment();
    if (!this.canDelete) {
      this.showMenu = false;
    }

  }

  isOwnComment() {
    if (!this.comment) { return false; }
    if (!this.auth.user) { return false; }
    return this.comment?.author?.id === this.auth.user?.id;
  }

  onEditorCreated() {
    // console.log(this.editor);
  }

  viewAuthorInSidePanel(author: BaseUserFragment){
    this.detailsHelper.open('users', {id: author.id});
  }

  findAuthorJobRoles(author: BaseUserFragment): BaseJobUserFragment[] {
    const job = this.job;
    if (!job?.users?.length) { return []; }
    if (!author) { return []; }

    const jobRoles = job.users
      .filter((u) => u.user?.id === author.id)
      .sort((a, b) => a.role > b.role ? -1 : 1);

    return jobRoles;
  }

  setMode(mode: CommentMode, check = true) {
    if (check && mode === 'view' && this.original) {
      this.contents = this.original;
    } else if (check && mode === 'edit') {
      this.original = this.contents;
    }

    this.mode = mode;

    if (mode === 'edit') {
      setTimeout(() => {
        this.replyEditor.quillEditor.focus();
      }, 100);
    }
  }

  save() {
    const text = this.editor.quillEditor.getText();
    const contents = this.editor.quillEditor.getContents();

    this.store.dispatch(JobToolActions.updateComment({
      input: {
        ids: [ this.comment.id ],
        update: {
          text,
          contents: JSON.stringify(contents),
        },
      },
    }));

    this.setMode('view', false);
  }

  openReply() {
    // if not parent then let parent know that we want to reply

    if (this.parent) {
      this.parent.openReply();
      return;
    }

    if (!this.replyEnabled) { return; }

    this.repliesExpanded = true;
    this.replyOpened = true;

    // focus reply editor, timeout required
    // because we may be in a focus event already
    setTimeout(() => {
      this.replyEditor.quillEditor.focus();
    }, 10);
  }

  closeReply() {
    this.replyOpened = false;
  }

  clearReply() {
    this.replyEditor?.quillEditor?.setContents([{ insert: '\n' }]);
  }

  canAddReply() {
    if (!this.replyEnabled) return false;
    if (!this.job) return false;
    if (this.addReplyIsEmpty()) { return false; }
    // TODO: check permissions

    return true;
  }

  addReply() {
    if (!this.canAddReply()) { return false; }

    const text = this.replyEditor.quillEditor.getText();
    const contents = this.replyEditor.quillEditor.getContents();
  
    const input: AddCommentInput = {
      objectId: this.job.id,
      objectType: 'Job',
      text,
      contents: JSON.stringify(contents),
      threadId: this.comment.thread.id,
    };

    const actionId = generateUUID();
    this.store.dispatch(JobToolActions.addComment({ input, temporaryCommentId: actionId }));
    this.clearReply();
  }

  addReplyIsEmpty() {
    return isQuillEmpty(this.replyEditor?.quillEditor);
  }
}
