import { AsyncPipe, CommonModule, CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";


import { MarkdownModule } from 'ngx-markdown';

import { AttentionItemsComponent } from '../attention-items/attention-items/attention-items.component';
import { AttentionItemsExplanationComponent } from '../attention-items/attention-items-explanation/attention-items-explanation.component';
import { CustomInputsModule } from '../custom-inputs/custom-inputs.module';


import { FreyaCommonModule } from '../freya-common/freya-common.module';
import { LibModule } from '../lib.ts/lib.module';
import { ReportDataComponent } from '../reports/report-data/report-data.component';

import { AbbreviateCurrencyPipe } from './abbreviate-currency.pipe';
import { AbbreviateNumberPipe } from './abbreviate-number.pipe';
import { ActivePricePipe } from './active-price.pipe';
import { AddCommentComponent } from './add-comment/add-comment.component';
import { AddEventButtonComponent } from './add-event-button/add-event-button.component';
import { AddFilterComponent } from './add-filter/add-filter.component';
import { AddLocationComponent } from './add-location/add-location.component';
import { ApolloErrorMessagePipe } from './apollo-error-message.pipe';
import { AppliedDiscountValuePipe } from './applied-discount-value.pipe';
import { ApplyTemplateComponent } from './apply-template/apply-template.component';
import { AppropriateTimeMeasurementPipe } from './appropriate-time-measurement.pipe';
import { AreaCodePipe } from './area-code.pipe';
import { ArtifactDialogComponent } from './artifact-dialog/artifact-dialog.component';
import { ArtifactTypeIconPipe } from './artifact-type-icon.pipe';
import { ArtifactUrlPipe } from './artifact-url.pipe';
import { AssetZonesPipe } from './asset-zones.pipe';
import { AttentionItemActionPipe } from './attention-item-action.pipe';
import { AttentionItemIconPipe } from './attention-item-icon.pipe';
import { AttentionItemNamePipe } from './attention-item-name.pipe';
import { AttributeFilterPipe } from './attribute-filter.pipe';
import { AuthComponent } from './auth/auth.component';
import { BlockableComponent } from './blockable/blockable.component';
import { BookOffComponent } from './book-off/book-off.component';
import { CanChangeZonePipe } from './can-change-zone.pipe';
import { CardBorderValuePipe } from './card-border-value.pipe';
import { ChangeUserPasswordComponent } from './change-user-password/change-user-password.component';
import { ChargeAmountPipe } from './charge-amount.pipe';
import { ChargeDamagePipe } from './charge-damage.pipe';
import { ChargeEventPipe } from './charge-event.pipe';
import { ChargeSubtotalPipe } from './charge-subtotal.pipe';
import { ChargePipe } from './charge.pipe';
import { CloseJobComponent } from './close-job/close-job.component';
import { ColorForBackgroundDirective } from './color-for-background.directive';
import { CombineArraysPipe } from './combine-arrays.pipe';
import { CompleteEventsConfirmationComponent } from './complete-events-confirmation/complete-events-confirmation.component';
import { ConfirmationWithLoadingStateComponent } from './confirmation-with-loading-state/confirmation-with-loading-state.component';
import { ContactAccountingComponent } from './contact-accounting/contact-accounting.component';
import { ControlDisabledDirective } from './control-disabled.directive';
import { CopyButtonComponent } from './copy-button/copy-button.component';
import { CreatePageComponent } from './create-page (deprecated)/create-page.component';
import { CustomCasePipe } from './custom-case.pipe';
import { DeleteJobComponent } from './delete-job/delete-job.component';
import { DeleteObjectComponent } from './delete-object/delete-object.component';
import { DiscountAmountPipe } from './discount-amount.pipe';
import { DiscountCustomAmountRangePipe } from './discount-custom-amount-range.pipe';
import { DiscountCustomAmountPipe } from './discount-custom-amount.pipe';
import { DiscountStatusPipe } from './discount-status.pipe';
import { DisplayEventComponent } from './display-event/display-event.component';
import { DisplayTagsComponent } from './display-tags/display-tags.component';
import { DistancePipe } from './distance.pipe';
import { DoubleClickDirective } from './double-click.directive';
import { DownloadJobInvoiceComponent } from './download-job-invoice/download-job-invoice.component';
import { DurationInputComponent } from './duration-input/duration-input.component';
import { EditCustomAmountComponent } from './edit-custom-amount/edit-custom-amount.component';
import { EditInPlaceComponent } from './edit-in-place/edit-in-place.component';
import { EditableListComponent } from './editable-list/editable-list.component';
import { EventAttendeePipe } from './event-attendee.pipe';
import { EventCustomerPipe } from './event-customer.pipe';
import { EventDurationFromCalendarPipe } from './event-duration-from-calendar.pipe';
import { EventDurationPipe } from './event-duration.pipe';
import { EventHasInvoicePipe } from './event-has-invoice.pipe';
import { EventLocationComponent } from './event-location/event-location.component';
import { EventLocationNamePipe } from './event-location-name.pipe';
import { FieldValuePipe } from './field-value.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FilterRemovedStepsPipe } from './filter-removed-steps.pipe';
import { FilterValuePipe } from './filter-value.pipe';
import { FinancialsTableComponent } from './financials-table/financials-table.component';
import { FormatAmountPipe } from './format-amount.pipe';
import { FormatTagPipe } from './format-tag.pipe';
import { FreyaCurrencyPipe } from './freya-currency.pipe';
import { FreyaDatePipe } from './freya-date.pipe';
import { FreyaProgressBarComponent } from './freya-progress-bar/freya-progress-bar.component';
import { GetObjectByIdPipe } from './get-object-by-id.pipe';
import { GoogleMapsUrlPipe } from './google-maps-url.pipe';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { HideStringPipe } from './hide-string.pipe';
import { IndexOfPipe } from './index-of.pipe';
import { InfoCircleComponent } from './info-circle/info-circle.component';
import { InvoiceNamePipe } from './invoice-name.pipe';
import { InvoiceStatusPipe } from './invoice-status.pipe';
import { JobActionsComponent } from './job-actions/job-actions.component';
import { JobAmountDuePipe } from './job-amount-due.pipe';
import { JobArtifactRelatedObjectsPipe } from './job-artifact-related-objects.pipe';
import { JobCustomerPipe } from './job-customer.pipe';
import { JobDatePipe } from './job-date.pipe';
import { JobExtrasFilterPipe } from './job-extras-filter.pipe';
import { JobLocationSelectComponent } from './job-location-select/job-location-select.component';
import { JobLocationPipe } from './job-location.pipe';
import { JoinPipe } from './join.pipe';
import { JurisdictionPipe } from './jurisdiction.pipe';
import { LazyLoadDirective } from './lazy-load.directive';
import { LocationPipe } from './location.pipe';
import { LongClickDirective } from './long-click.directive';
import { MaskedCalendarComponent } from './masked-calendar/masked-calendar.component';
import { MatchClassPipe } from './match-class.pipe';
import { MetadataPipe } from './metadata.pipe';
import { MiniStepsComponent } from './mini-steps/mini-steps.component';
import { MutateAreaComponent } from './mutate-area/mutate-area.component';
import { MutateAssetComponent } from './mutate-asset/mutate-asset.component';
import { MutateAvailabilityTemplateComponent } from './mutate-availability-template/mutate-availability-template.component';
import { MutateBookOffComponent } from './mutate-book-off/mutate-book-off.component';
import { MutateBusinessUnitComponent } from './mutate-business-unit/mutate-business-unit.component';
import { MutateCalendarEventComponent } from './mutate-calendar-event/mutate-calendar-event.component';
import { MutateChargesComponent } from './mutate-charges/mutate-charges.component';
import { MutateCommentComponent } from './mutate-comment/mutate-comment.component';
import { MutateDiscountComponent } from './mutate-discount/mutate-discount.component';
import { MutateDynamicReportComponent } from './mutate-dynamic-report/mutate-dynamic-report.component';
import { MutateFilterComponent } from './mutate-filter/mutate-filter.component';
import { MutateInvoiceComponent } from './mutate-invoice/mutate-invoice.component';
import { MutateJobComponent } from './mutate-job/mutate-job.component';
import { MutateLocationComponent } from './mutate-location/mutate-location.component';
import { MutateObjectComponent } from './mutate-object/mutate-object.component';
import { MutatePriceComponent } from './mutate-price/mutate-price.component';
import { MutateProductComponent } from './mutate-product/mutate-product.component';
import { MutateRollingLockDateComponent } from './mutate-rolling-lock-date/mutate-rolling-lock-date.component';
import { MutateRuleConditionBlockComponent } from './mutate-rule/mutate-rule-condition-block/mutate-rule-condition-block.component';
import { MutateRuleComponent } from './mutate-rule/mutate-rule.component';
import { MutateTaxComponent } from './mutate-tax/mutate-tax.component';
import { MutateTransactionComponent } from './mutate-transaction/mutate-transaction.component';
import { MutateTransactionTypeComponent } from './mutate-transaction-type/mutate-transaction-type.component';
import { MutateUserComponent } from './mutate-user/mutate-user.component';
import { NotSetPipe } from './not-set.pipe';
import { NotSupportedComponent } from './not-supported/not-supported.component';
import { NotificationDatePipe } from './notification-date.pipe';
import { NotificationReadPipe } from './notification-read.pipe';
import { ObjectEditTableComponent } from './object-edit-table/object-edit-table.component';
import { ObjectNotFoundComponent } from './object-not-found/object-not-found.component';
import { ObjectStateComponent } from './object-state/object-state.component';
import { OpenLinkComponent } from './open-link/open-link.component';
import { ParseCronTimePipe } from './parse-cron-time.pipe';
import { PasswordConfirmComponent } from './password-confirm/password-confirm.component';
import { PendingEventsPipe } from './pending-events.pipe';
import { PermissionDirective } from './permission.directive';
import { PhonePipe } from './phone.pipe';
import { PrettyPropertyPipe } from './pretty-property.pipe';
import { RemainingJobBalancePipe } from './remaining-job-balance.pipe';
import { RemoveDisabledStepsPipe } from './remove-disabled-steps.pipe';
import { RenamePipe } from './rename.pipe';
import { ResolvePaymentIconPipe } from './resolve-payment-icon.pipe';
import { RulePropertyDirective } from './rule-property.directive';
import { SearchPipe } from './search.pipe';
import { SecondsToDurationPipe } from './seconds-to-duration.pipe';
import { SecondsToTimeUnitPipe } from './seconds-to-time-unit.pipe';
import { SecondsToTimePipe } from './seconds-to-time.pipe';
import { SendDocumentsComponent } from './send-documents/send-documents.component';
import { SendableDocumentComponent } from './sendable-document/sendable-document.component';
import { SeverityPipe } from './severity.pipe';
import { ShareEventZoneComponent } from './share-event-zone/share-event-zone.component';
import { SliceTextPipe } from './slice-text.pipe';
import { SortPipe } from './sort.pipe';
import { SpanSeperatorComponent } from './span-seperator/span-seperator.component';
import { TimeSinceComponent } from './time-since/time-since.component';
import { TransactionAvailabilityPipe } from './transaction-availability.pipe';
import { TrimWhitespacePipe } from './trim-whitespace.pipe';
import { TrustUrlPipe } from './trust-url.pipe';
import { TypeOfPipe } from './type-of.pipe';
import { TzDatePipe } from './tz-date.pipe';
import { UpdateEventLocationsDialogComponent } from './update-event-locations-dialog/update-event-locations-dialog.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { UserActionsComponent } from './user-actions/user-actions.component';
import { UserFranchisePipe } from './user-franchise.pipe';
import { UserInitialsPipe } from './user-initals.pipe';
import { UserNamePipe } from './user-name.pipe';
import { YemboDocumentsComponent } from './yembo-documents/yembo-documents.component';
import { ZoneChipComponent } from './zone-chip/zone-chip.component';
import { ZoneSelectComponent } from './zone-select/zone-select.component';

const declarations = [
  AbbreviateCurrencyPipe,
  AbbreviateNumberPipe,
  ActivePricePipe,
  AddCommentComponent,
  AddFilterComponent,
  AddLocationComponent,
  AppliedDiscountValuePipe,
  ApplyTemplateComponent,
  AppropriateTimeMeasurementPipe,
  AreaCodePipe,
  ArtifactDialogComponent,
  HelpDialogComponent,
  ArtifactTypeIconPipe,
  ArtifactUrlPipe,
  AssetZonesPipe,
  AttributeFilterPipe,
  AuthComponent,
  BookOffComponent,
  BlockableComponent,
  CloseJobComponent,
  CanChangeZonePipe,
  CardBorderValuePipe,
  ChangeUserPasswordComponent,
  ChargeAmountPipe,
  ChargeDamagePipe,
  ChargeEventPipe,
  ChargePipe,
  ChargeSubtotalPipe,
  ColorForBackgroundDirective,
  CombineArraysPipe,
  ConfirmationWithLoadingStateComponent,
  ContactAccountingComponent,
  ControlDisabledDirective,
  CopyButtonComponent,
  CreatePageComponent,
  CustomCasePipe,
  DeleteObjectComponent,
  DiscountAmountPipe,
  DiscountStatusPipe,
  DisplayEventComponent,
  DisplayTagsComponent,
  DistancePipe,
  DownloadJobInvoiceComponent,
  DurationInputComponent,
  EventAttendeePipe,
  EventCustomerPipe,
  EventDurationFromCalendarPipe,
  EventDurationPipe,
  EventHasInvoicePipe,
  EventLocationComponent,
  EventLocationNamePipe,
  FieldValuePipe,
  FileSizePipe,
  FilterRemovedStepsPipe,
  FilterValuePipe,
  FormatTagPipe,
  FreyaDatePipe,
  FreyaProgressBarComponent,
  GetObjectByIdPipe,
  GoogleMapsUrlPipe,
  HideStringPipe,
  IndexOfPipe,
  InfoCircleComponent,
  JobActionsComponent,
  JobAmountDuePipe,
  JobAmountDuePipe,
  JobArtifactRelatedObjectsPipe,
  JobCustomerPipe,
  JobDatePipe,
  JobExtrasFilterPipe,
  JobLocationPipe,
  JoinPipe,
  JurisdictionPipe,
  MaskedCalendarComponent,
  MatchClassPipe,
  MetadataPipe,
  MiniStepsComponent,
  MutateAreaComponent,
  MutateAssetComponent,
  MutateAvailabilityTemplateComponent,
  MutateCalendarEventComponent,
  MutateChargesComponent,
  MutateCommentComponent,
  MutateDiscountComponent,
  MutateDynamicReportComponent,
  MutateFilterComponent,
  MutateJobComponent,
  MutateLocationComponent,
  MutateObjectComponent,
  MutatePriceComponent,
  MutateProductComponent,
  MutateRuleComponent,
  MutateRuleConditionBlockComponent,
  MutateTaxComponent,
  MutateTransactionComponent,
  MutateTransactionTypeComponent,
  MutateUserComponent,
  NotificationReadPipe,
  NotSetPipe,
  NotSupportedComponent,
  ObjectNotFoundComponent,
  OpenLinkComponent,
  PasswordConfirmComponent,
  PendingEventsPipe,
  PermissionDirective,
  PhonePipe,
  PrettyPropertyPipe,
  RemainingJobBalancePipe,
  RemoveDisabledStepsPipe,
  RenamePipe,
  ReportDataComponent,
  ResolvePaymentIconPipe,
  RulePropertyDirective,
  SearchPipe,
  SecondsToDurationPipe,
  SecondsToTimePipe,
  SecondsToTimeUnitPipe,
  SendDocumentsComponent,
  SendableDocumentComponent,
  SeverityPipe,
  SliceTextPipe,
  TimeSinceComponent,
  TransactionAvailabilityPipe,
  TrimWhitespacePipe,
  TrustUrlPipe,
  TypeOfPipe,
  UploadFileComponent,
  UserFranchisePipe,
  UserInitialsPipe,
  UserNamePipe,
  ZoneChipComponent,
  ZoneSelectComponent,
  YemboDocumentsComponent,
  TzDatePipe,
  SpanSeperatorComponent,
  FreyaCurrencyPipe,
  FinancialsTableComponent,
  NotificationDatePipe,
  ObjectStateComponent,
  ShareEventZoneComponent,
  DeleteJobComponent,
  UserActionsComponent,
  DiscountCustomAmountRangePipe,
  DiscountCustomAmountPipe,
  EditCustomAmountComponent,
  ApolloErrorMessagePipe,
  FormatAmountPipe,
  EditInPlaceComponent,
  EditableListComponent,
  ObjectEditTableComponent,
  UpdateEventLocationsDialogComponent,
  AddEventButtonComponent,
  SortPipe,
  AttentionItemsComponent,
  AttentionItemsExplanationComponent,
  AttentionItemNamePipe,
  AttentionItemIconPipe,
  AttentionItemActionPipe,
  CompleteEventsConfirmationComponent,
  LongClickDirective,
  DoubleClickDirective,
  MutateBusinessUnitComponent,
  MutateBookOffComponent,
  JobLocationSelectComponent,
  MutateBusinessUnitComponent,
  ParseCronTimePipe,
  MutateRollingLockDateComponent,
  MutateInvoiceComponent,
  InvoiceStatusPipe,
  InvoiceNamePipe,
  LazyLoadDirective,
  LocationPipe,
];
@NgModule({
  declarations,
  providers: [
    CurrencyPipe,
    DatePipe,
    JobCustomerPipe,
    TitleCasePipe,
    RemoveDisabledStepsPipe,
    UserFranchisePipe,
    AreaCodePipe,
    RemainingJobBalancePipe,
    FreyaDatePipe,
    PrettyPropertyPipe,
    AsyncPipe,
    AbbreviateCurrencyPipe,
    AbbreviateNumberPipe,
    ActivePricePipe,
    NotificationReadPipe,
    FreyaCurrencyPipe,
    FormatAmountPipe,
  ],
  imports: [
    CommonModule,
    FreyaCommonModule,
    FormsModule,
    ReactiveFormsModule,
    LibModule,
    NgxGpAutocompleteModule,
    CustomInputsModule,
    MarkdownModule.forChild(),
  ],
  exports: [
    ...declarations,
    NgxGpAutocompleteModule,
    CustomInputsModule,
  ],
})
export class SharedModule { }
