import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AddCommentInput, BaseCommentFragment, CommentWithRepliesFragment, FullJobFragment, UpdateCommentInput } from '../../generated/graphql.generated';
import { UserProfile } from '../interfaces/auth';

export const JobToolActions = createActionGroup({
	source: 'Job Tool',
	events: {
		'Params Set': props<{
			jobId: string;
			// The currently logged in user
			user: UserProfile;
			tab: {
				name: string;
				index: number;
			};
		}>(),
		'Tab Changed': props<{
			name: string;
			index: number;
		}>(),

		'Job Loading': emptyProps(),

		'Job Loaded': props<{
			job: FullJobFragment;
			comments: CommentWithRepliesFragment[];
			totalComments: number;
		}>(),

		'Job Load Error': props<{
			error: Error;
		}>(),

		// "commentId" is a temporary ID used to track this comment before
		// it is assigned an ID by the backend
		'Add Comment': props<{ input: AddCommentInput, temporaryCommentId: string }>(),
		'Add Comment Success': props<{ comment: CommentWithRepliesFragment, commentId: string }>(),
		'Add Comment Error': props<{ error: Error, input: AddCommentInput, commentId: string }>(),
		'Comment Loaded': props<{ comment: CommentWithRepliesFragment }>(),
		'Comment Load Error': props<{ error: Error, commentId: string }>(),

		'Update Comment': props<{ input: UpdateCommentInput }>(),
		'Update Comment Success': props<{ comment: CommentWithRepliesFragment }>(),
		'Update Comment Error': props<{ error: Error, input: UpdateCommentInput }>(),

		'Delete Comment': props<{ comment: BaseCommentFragment }>(),
		'Delete Comment Success': props<{ comment: BaseCommentFragment }>(),
		'Delete Comment Error': props<{ error: Error, comment: BaseCommentFragment }>(),
	},
});
