/* eslint-disable @ngrx/prefix-selectors-with-select */
import { MemoizedSelector, createFeature, createReducer, createSelector, on } from '@ngrx/store';


import { BaseUserFragment, BaseZoneFragment, CommentWithRepliesFragment, FullCalendarEventFragment, FullJobFragment, JobLocation } from '../../generated/graphql.generated';

import { UserProfile } from '../interfaces/auth';
import { currentTimeSeconds } from '../time';
import { CallState, LoadingState } from '../utilities/state.util';


import { addCommentToComments, removeCommentFromArray, updateCommentInPlace } from './job-activity/comments.utils';
import { jobToolSubscriptionReducers } from './job-state/job-tool-subscription.reducers';
import { JobToolActions } from './job-tool.actions';

export interface JobToolFields {
	source?: string;
};

// States that are tracked by the job tool under "callState"
export const JOB_TOOL_CALL_STATES = [
	'job',
	'addComment',
	'updateComment',
	'deleteComment',
] as const;
export type JobToolCallState = typeof JOB_TOOL_CALL_STATES[number];

export interface JobToolComment extends CommentWithRepliesFragment {
	callState?: CallState;
}

export interface JobToolState {

	customer: BaseUserFragment | undefined;
	customerName: string;

	// the currently logged in user
	user: UserProfile | undefined;
	jobId: string | undefined;
	job: FullJobFragment | undefined;
  
	fields: JobToolFields | undefined;
  
	locations: JobLocation[] | undefined;
	events: FullCalendarEventFragment[] | undefined;
	zone: BaseZoneFragment | undefined;
	comments: JobToolComment[] | undefined;
	totalComments: number | undefined;

	tab: {
		name: string;
		index: number;
	};

	callState: {
		[ name in JobToolCallState ]: CallState;
	};
}

export const jobToolInitialState: JobToolState = {
	tab: {
		name: 'overview',
		index: 0,
	},
	
	callState: JOB_TOOL_CALL_STATES.reduce(
		(p, c) => ({...p, [c]: LoadingState.INIT}),
		{} as JobToolState['callState'],
	),

	customerName: 'No Customer',

	user: undefined,
	jobId: undefined,
	comments: undefined,
	customer: undefined,
	events: undefined,
	fields: undefined,
	job: undefined,
	locations: undefined,
	totalComments: undefined,
	zone: undefined,
};

export type LoadingSelectorType = MemoizedSelector<
	Record<string, any>,
	boolean,
	(s1: JobToolState['callState']) => boolean
>;

export type LoadingErrorSelectorType = MemoizedSelector<
	Record<string, any>,
	boolean,
	(s1: JobToolState['callState']) => string | null
>;

export const jobToolFeature = createFeature({
	name: 'jobTool',
	extraSelectors: ({
		selectCallState,
		selectComments,
	}) => {

		const loadingSelectors = JOB_TOOL_CALL_STATES.reduce((p, callState) => {
			const selectors = {
				[`${ callState }Loading`]: createSelector(
					selectCallState,
					(state) => state[callState] === LoadingState.PENDING
						|| state[callState] === LoadingState.LOADING
						|| state[callState] === LoadingState.MUTATING,
				),
				[`${ callState }Loaded`]: createSelector(
					selectCallState,
					(state) => state[callState] === LoadingState.LOADED
						|| state[callState] === LoadingState.MUTATED,
				),
				// [`${ callState }FullyLoaded`]: createSelector(
				// 	selectCallState,
				// 	// factorySelectors.
				// 	(state) => {

				// 		const isLoaded = [LoadingState.LOADED, LoadingState.MUTATED]
				// 			.includes(state[callState] as any);
				// 		return isLoaded && 
				// 	},
				// ),
			};

			return {
				...p,
				...selectors,
			};
		}, {} as {
			[x in
				`${ JobToolCallState }Loading` |
				`${ JobToolCallState }Loaded`
			]: LoadingSelectorType;
		});

		const errorSelectors = JOB_TOOL_CALL_STATES.reduce((p, callState) => {
			// eslint-disable-next-line @ngrx/prefix-selectors-with-select
			const selectors = {
				[`${ callState }Error`]: createSelector(
					selectCallState,
					(state) => {
						const cs = state[callState];
						if (typeof cs === 'object' && 'error' in cs) {
							return cs.error;
						}

						return null;
					}
				),
			};

			return {
				...p,
				...selectors,
			};
		}, {} as {
			[x in
				`${ JobToolCallState }Error`
			]: LoadingErrorSelectorType;
		});

		const isAnyLoading = createSelector(
			selectCallState,
			(callState) => {
				for (const key in callState) {
					if (callState[key] === LoadingState.LOADING) {
						return true;
					}
					if (callState[key] === LoadingState.MUTATING) {
						return true;
					}
				}

				return false;
			}
		);

		return {
			...loadingSelectors,
			...errorSelectors,
			isAnyLoading,
		};
	},
	reducer: createReducer(
		jobToolInitialState,
		on(JobToolActions.paramsSet, (state, res): JobToolState => {

			console.log(`PARAMS SET`);
	
			return {
				...state,
				user: res.user,
				jobId: res.jobId,
				tab: res.tab,
			};
		}),
		on(JobToolActions.tabChanged, (state, res): JobToolState => {
	
			return {
				...state,
				tab: {
					index: res.index,
					name: res.name,
				},
			};
		}),
		on(JobToolActions.jobLoading, (state, res): JobToolState => {
			
			// reset everything about the job state
			// except for a few values
			return {
				...jobToolInitialState,
				user: state.user,
				jobId: state.jobId,
				tab: state.tab,

				callState: {
					...jobToolInitialState.callState,
					job: LoadingState.LOADING,
				}
			};
		}),
		on(JobToolActions.jobLoaded, (state, res): JobToolState => {
			const {job} = res;
			const jobCustomer = job.users?.find((u) => u.role === 'customer');
			const customer = jobCustomer?.user;

			const customerName = [ customer.givenName, customer.familyName ]
				.filter(Boolean)
				.join(' ');

			return {
				...state,

				job,
				jobId: job.id,
				customer: jobCustomer?.user,
				customerName,
				zone: job.zone,

				// TODO: populate fields
				fields: {},

				events: res.job.events,
				locations: job.locations as JobLocation[],

				comments: res.comments,
				totalComments: res.totalComments,

				callState: {
					...state.callState,
					job: LoadingState.LOADED,
				},
			};
		}),
		on(JobToolActions.addComment, (state, { input, temporaryCommentId }): JobToolState => {

			const tempThreadId = `thread:${ temporaryCommentId }`;
	
			const comment: JobToolComment = {
				...input,
				id: temporaryCommentId,
				createdAt: currentTimeSeconds(),
				replies: [],
				author: state.user,
				thread: {
					id: input.threadId || tempThreadId,
				},
				callState: LoadingState.MUTATING,
			};

			const {comments} = addCommentToComments(state.comments, comment);
	
			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.addCommentSuccess, (state, res): JobToolState => {

			const comment: JobToolComment = {
				...res.comment,
				callState: LoadingState.MUTATED,
			};

			const { comments } = updateCommentInPlace(
				state.comments,
				res.commentId,
				comment,
				false,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.addCommentError, (state, {
			commentId,
			error,
		}): JobToolState => {

			const comment: Partial<JobToolComment> = {
				callState: {
					error,
				},
			};

			// TODO: is the error because we disconnected?
			// Then mark this comment as PENDING
			// downside of this is that we can only add one pending comment
			// at a time... though if you have an array of pending
			// changes perhaps not?
			// console.error(`Add Comment Error`, state);
			const {comments} = updateCommentInPlace(
				state.comments,
				commentId,
				comment,
				true,
			);
	
			return {
				...state,
				comments,
				callState: {
					...state.callState,
				},
			};
		}),
		on(JobToolActions.updateComment, (state, { input }): JobToolState => {
			const update = {
				...input.update,
				callState: LoadingState.MUTATING,
			};

			const {comments} = updateCommentInPlace(
				state.comments,
				input.ids[0],
				update,
				true,
			);
	
			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.updateCommentSuccess, (state, res): JobToolState => {
			const comment = {
				...res.comment,
				callState: LoadingState.MUTATED,
			};

			const {comments} = updateCommentInPlace(
				state.comments,
				res.comment.id,
				comment,
				false,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.updateCommentError, (state, res): JobToolState => {
			const update = {
				callState: {
					error: res.error,
				},
			} as JobToolComment;

			const {comments} = updateCommentInPlace(
				state.comments,
				res.input.ids[0],
				update,
				true,
			);

			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.deleteComment, (state, { comment }): JobToolState => {
			const {
				comments,
			} = removeCommentFromArray(
				state.comments,
				comment.id,
			);
	
			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.deleteCommentSuccess, (state, { comment }): JobToolState => {
			return {
				...state,
			};
		}),
		on(JobToolActions.deleteCommentError, (state, res): JobToolState => {
			const update = {
				callState: {
					error: res.error,
				},
			} as JobToolComment;

			const {comments} = updateCommentInPlace(
				state.comments,
				res.comment.id,
				update,
				true,
			);
			return {
				...state,
				comments,
			};
		}),
		on(JobToolActions.commentLoaded, (state, res): JobToolState => {
			// we can just update the comment in place
			// because this assumes that it has already been
			// added but maybe missing some information (like author)
			const {comments, comment} = updateCommentInPlace(
				state.comments,
				res.comment.id,
				res.comment,
				false,
			);

			return {
				...state,
				comments,
			};
		}),
		...jobToolSubscriptionReducers,
	),
});

