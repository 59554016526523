import { inject } from '@angular/core';

import { Router } from '@angular/router';
import { dayjs } from '@karve.it/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import {
    Asset,
    BulkEditCalendarEventGQL,
    BulkEditCalendarEventMutationVariables,
    ListBaseAssetsGQL,
    ListBaseAssetsQueryVariables,
    ListRolesGQL,
    ListRolesQueryVariables,
    ListUsersGQL,
    ListUsersQueryVariables,
    Role,
    ScheduleEventsGQL,
    ScheduleEventsQueryVariables,
    SingleEditInput,
    User,
    ZoneDir,
} from '../../../../generated/graphql.generated';

import { FreyaNotificationsService } from '../../../services/freya-notifications.service';

import { PageTitleService } from '../../../services/page-title.service';

import { ScheduleActions } from '../../store/schedule.actions';

import { DispatchActions } from './dispatch.actions';


import {
    AttendeeWithName,
    DispatchFeature,
    EventStatuses,
    UpdateCrewActionPayload,
} from './dispatch.reducer';




function calculateDateRange(date: Date): { min: number; max: number } {
    const startOfDay = dayjs(date).startOf('day').unix();
    const endOfDay = dayjs(date).endOf('day').unix();
    return { min: startOfDay, max: endOfDay };
}

interface handleNotificationProps {
    notify: FreyaNotificationsService;
    edits: UpdateCrewActionPayload[];
    eventName: string;
    success?: boolean;
}

const handleNotification = ({
    notify,
    edits,
    eventName,
    success = true,
}: handleNotificationProps) => {
    const notifyType = success ? 'success' : 'error';

    if (edits.length > 1) {
        const bulkMessage = success
            ? `Dispatch Event for "${eventName}" updated successfully.`
            : `Failed to update dispatch event for "${eventName}".`;
        notify[notifyType](bulkMessage);
        return;
    }

    const { addAttendees = [], removeAttendees = [], addAssets = [], removeAssets = [] } = edits[0];
    const notificationItems = [
        {
            items: addAttendees,
            successMessage: `Crew member added to dispatch event for "${eventName}".`,
            errorMessage: `Failed to add crew member to dispatch event for "${eventName}".`
        },
        {
            items: removeAttendees,
            successMessage: `Crew member removed from dispatch event for "${eventName}".`,
            errorMessage: `Failed to remove crew member from dispatch event for "${eventName}".`
        },
        {
            items: addAssets,
            successMessage: `Truck added to dispatch event for "${eventName}".`,
            errorMessage: `Failed to add truck to dispatch event for "${eventName}".`
        },
        {
            items: removeAssets,
            successMessage: `Truck removed from dispatch event for "${eventName}".`,
            errorMessage: `Failed to remove truck from dispatch event for "${eventName}".`
        }
    ];

    notificationItems.forEach(({ items, successMessage, errorMessage }) => {
        if (items.length > 0) {
            notify[notifyType](success ? successMessage : errorMessage);
        }
    });
};

export const changeUrl = createEffect(
    (actions$ = inject(Actions), store = inject(Store), router = inject(Router), titleService = inject(PageTitleService)) => {
        return actions$.pipe(
            ofType(DispatchActions.setDispatchDate, DispatchActions.hardRefreshEvents),
            concatLatestFrom(() => store.select(DispatchFeature.selectDispatchDate)),
            map(([_, date]) => {
                const dayJs = dayjs(date);
                try {
                    const pageTitle = `Schedule: Dispatch - ${dayJs.format('MMM D, YYYY')}`;

                    titleService.setCustomPageTitle({
                        pageTitle,
                    });

                    router.navigate(['/schedule/dispatch/'], {
                        queryParamsHandling: 'merge',
                        queryParams: { date: dayJs.format('YYYY-MM-DD') },
                    });

                } catch (error) {
                    console.error('Error updating URL:', error);
                }
                /**
                * We want to get the date from `scheduleFeature` and use it in the `dispatchFeature`.
                * Ideally, we would remove the date from `dispatchFeature` and use the date from `scheduleFeature` directly.
                * However, since we are injecting stores directly into the route, we need to sync the date between the two features.
                * As both states load independently, we need to set the date in `dispatchFeature` after it is set in `scheduleFeature`.
                */
                return ScheduleActions.setDate({ date: dayJs });
            }),
        );
    },
    { dispatch: true, functional: true },
);

export const getEvents = createEffect(
    (
        actions$ = inject(Actions),
        scheduleEventsGQL = inject(ScheduleEventsGQL),
        store = inject(Store),
    ) => {
        return actions$.pipe(
            ofType(
                DispatchActions.setDispatchDate,
                DispatchActions.setEventStatus,
                DispatchActions.hardRefreshEvents,
                DispatchActions.componentHardRefreshed
            ),
            withLatestFrom(
                store.select(DispatchFeature.selectDispatchDate),
                store.select(DispatchFeature.selectEventStatus),
            ),
            exhaustMap(([action, date, status]) => {
                store.dispatch(DispatchActions.eventsLoading());

                const { min, max } = calculateDateRange(date);
                const variables: ScheduleEventsQueryVariables = {
                    filter: {
                        min,
                        max,
                    },
                    limit: 100, //TODO: Fix pagination
                };

                if (status != EventStatuses.All) {
                    variables.filter.status = status;
                }

                const fetchPolicy =
                    action.type === DispatchActions.hardRefreshEvents.type || action.type === DispatchActions.componentHardRefreshed.type
                        ? 'network-only' : 'cache-first';

                return scheduleEventsGQL.fetch(variables, { fetchPolicy }).pipe(
                    map((res) => {
                        if (res.loading || !res?.data) {
                            return DispatchActions.eventsLoading();
                        }

                        const { calendarEvents } = res.data;

                        return DispatchActions.eventsLoaded({
                            events: calendarEvents.events,
                        });
                    }),

                    catchError((error) => {
                        return of(DispatchActions.eventsLoadError({ error }));
                    }),
                );
            }),
        );
    },
    { functional: true, dispatch: true },
);


export const GetRoles = createEffect(
    (actions$ = inject(Actions), listRolesGQL = inject(ListRolesGQL)) => {
        return actions$.pipe(
            ofType(DispatchActions.initCrew, DispatchActions.componentHardRefreshed),
            exhaustMap((action) => {
                const variables: ListRolesQueryVariables = {
                    search: {
                        zoneDir: ZoneDir.Any,
                        limit: -1,
                    },
                };

                const fetchPolicy = action.type === DispatchActions.componentHardRefreshed.type ? 'network-only' : 'cache-first';

                return listRolesGQL.fetch(variables, { fetchPolicy }).pipe(
                    map((res) => {
                        if (res.loading || !res?.data) {
                            return DispatchActions.rolesLoading();
                        }

                        const { roles } = res.data;

                        const actionType = action.type === DispatchActions.componentHardRefreshed.type
                            ? DispatchActions.rolesLoadedOnComponentHardRefresh
                            : DispatchActions.rolesLoaded;

                        return actionType({ roles: roles as Role[] });
                    }),

                    catchError((error) => {
                        return of(DispatchActions.rolesLoadError({ error }));
                    }),
                );
            }),
        );
    },
    { functional: true, dispatch: true },
);

export const GetAssets = createEffect(
    (actions$ = inject(Actions), listAssets = inject(ListBaseAssetsGQL)) => {
        return actions$.pipe(
            ofType(DispatchActions.initCrew, DispatchActions.componentHardRefreshed),
            exhaustMap((action) => {
                const variables: ListBaseAssetsQueryVariables = {
                    filter: {
                        types: ['Truck'],
                    },
                    limit: -1,
                };

                const fetchPolicy = action.type === DispatchActions.componentHardRefreshed.type ? 'network-only' : 'cache-first';

                return listAssets.fetch(variables, { fetchPolicy }).pipe(
                    map((res) => {
                        if (res.loading || !res?.data) {
                            return DispatchActions.assetsLoading();
                        }

                        const { assets } = res.data;

                        return DispatchActions.assetsLoaded({
                            assets: assets.assets as Asset[],
                        });
                    }),

                    catchError((error) => {
                        return of(DispatchActions.assetsLoadError({ error }));
                    }),
                );
            }),
        );
    },
    { functional: true, dispatch: true },
);
export const GetUsers = createEffect(
    (
        actions$ = inject(Actions),
        store = inject(Store),
        listUsersGQL = inject(ListUsersGQL),
    ) => {
        return actions$.pipe(
            ofType(DispatchActions.setUserSearch, DispatchActions.setUserRole,
                DispatchActions.rolesLoaded, DispatchActions.rolesLoadedOnComponentHardRefresh),
            withLatestFrom(
                store.select(DispatchFeature.selectUserSearch),
                store.select(DispatchFeature.selectCrewRoleIds),
            ),
            exhaustMap(([action, search, crewRoleIds]) => {
                if (crewRoleIds.length === 0) {
                    return EMPTY;
                }

                store.dispatch(DispatchActions.usersLoading());

                const variables: ListUsersQueryVariables = {
                    filter: {
                        search,
                        roles: crewRoleIds,
                    },
                    limit: -1,
                };

                const fetchPolicy = action.type === DispatchActions.rolesLoadedOnComponentHardRefresh.type
                    ? 'network-only' : 'cache-first';

                return listUsersGQL.fetch(variables, { fetchPolicy }).pipe(
                    map((res) => {
                        if (res.loading || !res?.data) {
                            return DispatchActions.usersLoading();
                        }

                        const { usersv2 } = res.data;

                        return DispatchActions.usersLoaded({
                            users: usersv2.users as User[],
                        });
                    }),

                    catchError((error) => {
                        return of(DispatchActions.usersLoadError({ error }));
                    }),
                );
            }),
        );
    },
    { functional: true, dispatch: true },
);

export const UpdateCrew = createEffect(
    (
        actions$ = inject(Actions),
        bulkEditCalendarEventGQL = inject(BulkEditCalendarEventGQL),
        notify = inject(FreyaNotificationsService),
    ) => {
        return actions$.pipe(
            ofType(DispatchActions.updateCrew),
            exhaustMap(({ edits }) => {
                const { eventName } = edits[0];
                const mapAttendees = (attendees: AttendeeWithName[]) =>
                    attendees.map(({ user: { id }, role }) => ({
                        userId: id,
                        role,
                    }));

                const createEditInput = ({
                    eventId,
                    addAttendees = [],
                    removeAttendees = [],
                    addAssets = [],
                    removeAssets = [],
                }): SingleEditInput => ({
                    id: eventId,
                    edit: {
                        setAttendees: {
                            addAttendees: mapAttendees(addAttendees),
                            removeAttendees: mapAttendees(removeAttendees),
                        },
                        setAssets: {
                            addAssets: addAssets.map(({ id }) => id),
                            removeAssets: removeAssets.map(({ id }) => id),
                        },
                    },
                });

                const input: BulkEditCalendarEventMutationVariables = {
                    edits: edits.map(createEditInput),
                };

                return bulkEditCalendarEventGQL.mutate(input).pipe(
                    map((res) => {
                        if (res.data?.bulkEditCalendarEvent) {
                            handleNotification({ notify, edits, eventName });
                            return DispatchActions.updateCrewSuccess({ edits });
                        }

                        if (res.errors.length) {
                            handleNotification({ notify, edits, eventName, success: false });
                            console.error(`Failed to update event for "${eventName}".`, res.errors);
                            return DispatchActions.updateCrewError({
                                edits,
                                error: res.errors[0],
                            });
                        }
                    }),
                    catchError((error) => {
                        handleNotification({ notify, edits, eventName, success: false });
                        console.error(`Failed to update event for "${eventName}".`, error);
                        return of(
                            DispatchActions.updateCrewError({
                                edits,
                                error: error,
                            }),
                        );
                    }),
                );
            }),
        );
    },
    { functional: true, dispatch: true },
);
