import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';


import { Store } from '@ngrx/store';

import { BaseCommentFragment } from '../../../generated/graphql.generated';
import { jobToolFeature } from '../job-tool.reducer';

import { JobAddCommentComponent } from './job-add-comment/job-add-comment.component';
import { JobCommentComponent } from './job-comment/job-comment.component';

@Component({
  selector: 'app-job-activity',
  standalone: true,
  imports: [
    CommonModule,
    JobCommentComponent,
    JobAddCommentComponent,
  ],
  templateUrl: './job-activity.component.html',
  styleUrl: './job-activity.component.scss'
})
export class JobActivityComponent {

  comments$ = this.store.select(jobToolFeature.selectComments);
  jobId$ = this.store.select(jobToolFeature.selectJobId);
  job$ = this.store.select(jobToolFeature.selectJob);

  constructor(
    private store: Store,
  ) { }

  identifyComment(index: number, comment: BaseCommentFragment) {
    return comment?.id;
  }


}
