import { Injectable } from '@angular/core';
import {dayjs} from '@karve.it/core';
import { ReportService } from '@karve.it/features';
import {QueryRef} from 'apollo-angular';

import { isNumber } from 'lodash';
import { BehaviorSubject, forkJoin, from, merge, Observable, of, Subscription } from 'rxjs';
import { concatMap, debounceTime, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseReportTypeFragment, DashboardsV1Args, Report, ReportType, V1DashboardGQL, V1DashboardQuery } from '../../generated/graphql.generated';
import { dashboardVersionLsKey } from '../home/home.component';
import { AdvancedPeriodOptions, Period, PeriodOptions } from '../reports/reports.constants';
import { BrandingService } from '../services/branding.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { currentTimeSeconds, S_FOUR_WEEKS, S_ONE_DAY, S_ONE_YEAR } from '../time';

import { aggregateEventSummary } from './aggregation-functions';

import { AggregationDataMapFunc, AggregationMap, CachedReport, DashboardData, DashboardModuleData } from './dashboard.constants';
import { PeriodService } from './period.service';

const DASHBOARD_VERSIONS = [ 'v1', 'v2' ] as const;

export type DashboardVersion = typeof DASHBOARD_VERSIONS[number];

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    periodOption: AdvancedPeriodOptions = 'Last 7 days';
    period: Period;
    aggregateInterval: 'hour' | 'day' | 'month' | 'year' = 'day';

    initialized = false;
    currency = environment.defaultCurrency;
    units = environment.defaultUnits;

    reports: {
        [key: string]: {
            reportTypeId: string;
            reportType?: BaseReportTypeFragment;
            reportCache: {
                [periodKey: string]: CachedReport;
            };
        };
    } = {};

    reportsToLoad = [
        // active-jobs
        // 'db-job-counts',

        'db-created-events',
        '5020.0-expected-revenue',
        'db-happening-events',
        'db-csc-conversion',
        'db-current-jobs',
        'db-logistics-distances',
        'db-obe-conversion',
        'db-ose-conversion',
        'db-transaction-volume',
    ];

    reportsLoadLastPeriod = [
        // 'current-jobs',
        '5020.0-expected-revenue',
        // 'active-jobs',
        'logistics-distances',
    ];

    onDataUpdated = new BehaviorSubject<[string, DashboardData]>([ undefined, {} ]);

    queryRef: QueryRef<V1DashboardQuery>;
    querySub: Subscription;

    private _dashboardVersion$ = new BehaviorSubject<DashboardVersion>('v1');

    dashboardVersion$ = this._dashboardVersion$.asObservable();

    constructor(
        private reportService: ReportService,
        private v1DashboardGQL: V1DashboardGQL,
        private periodService: PeriodService,
        private brandingSvc: BrandingService,
        private freyaHelper: FreyaHelperService,
        private notify: FreyaNotificationsService,
    ) {
    }

    async load(reload = false) {
        if (reload) {
            this.reset();
        }

        if (this.queryRef) {
            this.queryRef.setVariables(this.getDashboardVariables());
            return;
        }

        await this.loadCountryValues();

        this.queryRef = this.v1DashboardGQL.watch(this.getDashboardVariables(), {
            fetchPolicy: 'cache-and-network',
        });

        // avoid overloading the rendering engine with a debounceTime
        this.querySub = this.queryRef.valueChanges.pipe(debounceTime(50)).subscribe((res) => {
            const data = res.loading ? undefined : res?.data?.dashboards?.v1;

            if (res.networkStatus === 7) {
                // console.log(`Dashboard data updated: `, data);
            }

            this.onDataUpdated.next([ 'v1', data || {} ]);
        }, (err) => {
            console.error(`Error loading dashboard:`, err);
            this.reset();
            this.notify.error('Error Loading Dashboard', err.message, 8000);
        });

        this.initialized = true;
    }

    getDashboardVariables(): DashboardsV1Args {

        const period = this.getPeriod() || this.period;
        const durationDays = dayjs(period.end * 1000).diff(dayjs(period.start * 1000), 'days');
        let aggregateTime = 'YYYY';
        if (durationDays < 2) {
            this.aggregateInterval = 'hour';
            aggregateTime = 'YYYY-MM-dd HH:00';
        } else if (durationDays < 60) {
            this.aggregateInterval = 'day';
            aggregateTime = 'YYYY-MM-dd';
        } else if (durationDays < 800) {
            this.aggregateInterval = 'month';
            aggregateTime = 'YYYY-MM';
        } else {
            this.aggregateInterval = 'year';
            aggregateTime = 'YYYY';
        }

        // console.log(period, durationDays, this.aggregateInterval, aggregateTime);

        const args: DashboardsV1Args = {
            input: {
                dashboardItems: [
                  'currencies',
                  'revenue',
                  'jobPipeline',
                  'oseConversion',
                  'cscConversion',
                  'obeConversion',
                  'travel',
                  'createdEvents',
                  'happeningEvents',
                  'txVolume',
                ],
                currency: this.currency,
                tz: this.brandingSvc.currentBranding?.value?.timezone,
                aggregateTime,
                period: this.periodOption,
            },
        };

        if (this.periodOption === 'Custom') {
            delete args.input.period;
            args.input.start = this.period.start;
            args.input.end = this.period.end;
        }

        return args;
    }

    reset() {
        this.initialized = false;
        this.currency = environment.defaultCurrency;
        this.units = environment.defaultUnits;
        delete this.queryRef;
        this.querySub?.unsubscribe();

        this.reports = {};
        this.onDataUpdated.next([ undefined, {} ]);
    }

    /**
     * Given the dashboard service period, returns the start and end for
     * in unix time for that period.
     *
     * @returns start and end as and object that can be passed to the report
     */
    getPeriod(periodValue?): { start: number; end: number } {
        return this.periodService.getUnixPeriod(this.periodOption, periodValue);
    }

    getPeriodStr() {
        if (this.periodOption === 'Custom' && this.period) {
            return `${
                dayjs(this.period.start * 1000).format('DD-MM-YYYY')
            } - ${
                dayjs(this.period.end * 1000).format('DD-MM-YYYY')
            }`;
        }

        return this.periodOption;

    }

    loadCountryValues() {
        return Promise.all([
            this.freyaHelper.getCurrency(),
            this.freyaHelper.getUnits(),
        ]).then(([ currency, units ]) => {
            this.currency = currency;
            this.units = units;
        }).catch((err) => {
            console.error(`Could not set proper currency / units`, err);
            this.currency = environment.defaultCurrency;
            this.units = environment.defaultUnits;
        });
    }

    generateReport(
        key: string,
        offset = 0,
        cacheInterval = 200,
        periodValue?: Period,
    ) {
        const reportTypeId = this.reports[key].reportTypeId;
        const period = this.periodService.offsetUnixPeriod(this.getPeriod(periodValue), offset);
        const periodKey = this.periodService.generateKey(period);

        const cached = this.reports[key].reportCache[periodKey];
        if (cached && currentTimeSeconds() - cached.createdAt < cacheInterval) {
            return of(cached);
        }

        // TODO: pass timezone to reports
        return this.reportService.createReport({
            input: {
                reportType: reportTypeId,
                report: {
                    saveData: false,
                    saveReport: false,
                    saveAggregations: false,
                    variables: JSON.stringify({
                        ...period,
                    }),
                }
            }
        }).pipe(map((res) => {
            // Turn aggregations into a map based on their title
            const report = res.data?.createReport;
            const agMap = this.createAggregationMap(report as any);

            const result: CachedReport = {
                key,
                report,
                agMap,
                createdAt: currentTimeSeconds(),
                period,
                periodKey,
            };

            if (!this.reports[key]) {
                // don't set the report result if the key has been removed
                // we already did this check above, and we want to avoid race conditions
                return;
                // this.reports[key] = {
                //     reportTypeId: report.reportType.id,
                //     reportCache: {},
                //     reportType: report.reportType,
                // };
            }

            this.reports[key].reportCache[periodKey] = result;
            return result;
        }));
    }

    createAggregationMap(report: Report) {
        const agMap: {
            [title: string]: typeof report.aggregations[number];
        } = {};

        if (report && report.aggregations) {
            for (const ag of report.aggregations) {
                if (ag.error) {
                    console.warn(`Encountered aggregation error `
                        + `[ ${ report.name || report.id } - ${ ag.title }]: ${ ag.error }`,
                        ag,
                    );
                }
                agMap[ag.title] = ag;
            }
        }

        return agMap;
    }

    generateReportAggregationData(
        report: CachedReport,
    ) {
        const {
            key: agKey,
            period: currentPeriod,
            periodKey: currentPeriodKey,
        } = report;

        const lastPeriod = this.periodService.offsetUnixPeriod(currentPeriod, -1);
        const lastPeriodKey = this.periodService.generateKey(lastPeriod);

        // TODO: move this out to a seperate file
        const aggregationDataMap: {
            [ key: string ]: AggregationDataMapFunc;
        } = {
            '5020.0-expected-revenue': ({ agMap, lastAgMap }) => ({
                realizedRevenue: this.aggregationToCurrencyData('SUM of realizedRevenue', agMap, lastAgMap),
                scheduledRevenue: this.aggregationToCurrencyData('SUM of unrealizedRevenue', agMap, lastAgMap),
                expectedRevenue: this.aggregationToCurrencyData('SUM of expectedRevenue', agMap, lastAgMap),
            }),
            // eslint-disable-next-line arrow-body-style
            'db-current-jobs': ({ agMap, lastAgMap }) => {

                // eslint-disable-next-line arrow-body-style
                // const calcExpectedRevenue = (funcAgMap: AggregationMap) => {
                //     return (funcAgMap['Realized Revenue']?.values || 0) + (funcAgMap['Scheduled Revenue Subtotal']?.values);
                // };

                return {
                    leadCount: this.aggregationToNumberData('lead count', agMap, lastAgMap),
                    estimateCount: this.aggregationToNumberData('estimate count', agMap, lastAgMap),
                    bookingCount: this.aggregationToNumberData('booking count', agMap, lastAgMap),
                    invoiceCount: this.aggregationToNumberData('invoice count', agMap, lastAgMap),
                    cancelledCount: this.aggregationToNumberData('cancelled count', agMap, lastAgMap),
                    avgActiveJobSize: this.aggregationToCurrencyData('Average Job Size', agMap, lastAgMap),
                    // realizedRevenue: this.aggregationToCurrencyData('Realized Revenue', agMap, lastAgMap),
                    // scheduledRevenue: this.aggregationToCurrencyData('Scheduled Revenue Subtotal', agMap, lastAgMap),
                    // scheduledRevenueTotal: this.aggregationToCurrencyData('Scheduled Revenue Total', agMap, lastAgMap),
                    // scheduledRevenuePaidTotal: this.aggregationToCurrencyData('Scheduled Revenue Paid Total', agMap, lastAgMap),
                    // expectedRevenue: this.funcToData(calcExpectedRevenue, agMap, lastAgMap, (res) => this.currencyDataModifier(res)),
                };

            },
            'db-ose-conversion': ({ agMap, lastAgMap }) => {

                const { total, converted, cancelled } = agMap;
                // TODO: set error in return
                if (!total || !converted || !cancelled) { return; }

                return {
                    oseConversion: this.valueToData(converted.values / total.values),
                    oseCancellation: this.valueToData(cancelled.values / ( total.values )),
                };
            },
            'db-csc-conversion': ({ agMap, lastAgMap }) => {

                const { total, converted, cancelled } = agMap;
                // TODO: set error in return
                if (!total || !converted || !cancelled) { return; }

                return {
                    cscConversion: this.valueToData(converted.values / total.values),
                    cscCancellation: this.valueToData(cancelled.values / total.values),
                };
            },
            'db-obe-conversion': ({ agMap, lastAgMap }) => {

                const { total, converted, cancelled } = agMap;
                // TODO: set error in return
                if (!total || !converted || !cancelled) { return; }

                return {
                    obeConversion: this.valueToData(converted.values / total.values),
                    obeCancellation: this.valueToData(cancelled.values / total.values),
                };
            },
            'db-logistics-distances': ({ agMap, lastAgMap }) => ({
                    totalDistanceCompleted: this.aggregationToDistanceData('distance completed', agMap, lastAgMap),
                    totalDistanceBooked: this.aggregationToDistanceData('distance booked', agMap, lastAgMap),
                    totalDistance: this.aggregationToDistanceData('distance', agMap, lastAgMap),
                    totalTravelTimeCompleted: this.aggregationToTimeData('travel time completed', agMap, lastAgMap),
                    totalTravelTimeBooked: this.aggregationToTimeData('travel time booked', agMap, lastAgMap),
                    totalTravelTime: this.aggregationToTimeData('travel time', agMap, lastAgMap),
                }),
            'db-created-events': aggregateEventSummary('createdEvents', this.currency),
            'db-happening-events': aggregateEventSummary('happeningEvents', this.currency),
            'db-transaction-volume': ({ agMap, lastAgMap }) => {
                const diff = currentPeriod.end - currentPeriod.start;

                const formats = {
                    year: 'YYYY',
                    month: 'MMM \'YY',
                    day: 'MMM Do',
                    hour: 'MMM D HH:mm',
                };

                let range: keyof typeof formats = 'year';
                const HOUR_RANGE = S_ONE_DAY * 2;
                const DAY_RANGE = S_FOUR_WEEKS * 3;
                const MONTH_RANGE = S_ONE_YEAR * 2;

                if (diff < HOUR_RANGE) {
                    range = 'hour';
                } else if (diff < DAY_RANGE) {
                    range = 'day';
                } else if (diff < MONTH_RANGE) {
                    range = 'month';
                }

                const periodAgs = Object.entries(agMap)
                    .filter(([ _, val ]) =>
                        val.type === 'PERIODS' && val.title.includes('paid total') && val.options
                );

                const [ key, ag ] = periodAgs.find(([ vKey, vAg ]) => vAg.options?.unit === range);

                if (!ag) { return; }

                // TODO: shortFormat and useShortFormat?
                const format = formats[range];
                const labels: string[] = [];
                const values: number[] = [];

                ag.labels.forEach((unix, i) => {
                    const time = dayjs((+unix) * 1000);

                    if (!time || !time.isValid()) { return; }
                    // TODO: check these
                    // filter labels/values
                    if (time.unix() < currentPeriod.start) { return; }
                    if (time.unix() > currentPeriod.end) { return; }

                    const label = time.format(format);
                    const value = (+ag.values[i]) / (100);

                    labels.push(label);
                    values.push(value);
                });

                // limit number of data points horizontally to MAX_DATA
                // const MAX_DATA = 12;
                // if (labels.length > MAX_DATA) {
                //     const every = labels.length / MAX_DATA;
                //     const lastIndex = labels.length - 1;
                //     const last: [ string, number ] = [ labels[lastIndex], values[lastIndex] ];

                //     labels = labels.filter((l, i) => i % every < 1);
                //     values = values.filter((d, i) => i % every < 1);

                //     // add final label if it is not included
                //     if ((lastIndex % every) >= 1 && lastIndex >= 0) {
                //         labels.push(last[0]);
                //         values.push(last[1]);
                //     }
                // }

                return {
                    transactionVolume: {
                        loaded: true,
                        labels,
                        values,
                    }
                };
            },
        };

        // retrieve existing data to be overwritten
        let data: DashboardData = { ...this.onDataUpdated.value[1] };

        // retrieve current and last agMaps
        if (!this.reports[agKey]) {
            return 'report type not found';
        }
        const reportData = this.reports[agKey];
        const current = reportData.reportCache[currentPeriodKey];
        const last = reportData.reportCache[lastPeriodKey];
        if (!current) {
            // something went wrong
            return `current${ !last ? ' (and last)' : '' } report not found`;
        }

        if (!aggregationDataMap[agKey]) { return 'agMap not found'; }

        // call data map function with current and last agMaps
        const newData = aggregationDataMap[agKey]({
            report: current.report as any,
            agMap: current.agMap,
            lastReport: last?.report as any,
            lastAgMap: last?.agMap,
        });

        // load newData onto data
        data = {
            ...data,
            ...newData,
        };

        // update behaviour subject
        this.onDataUpdated.next([ agKey, data ]);
        return 'ok';
    }

    aggregationToNumberData(title: string, agMap: AggregationMap, lastAgMap: AggregationMap): DashboardModuleData {
        if (!agMap) { return { loaded: false }; }
        const ag = agMap[title];
        if (!ag) { return { loaded: false }; }

        const data: DashboardModuleData = {
            loaded: true,
            amount: ag.values || 0,
        };

        if (lastAgMap && lastAgMap[title]) {
            const lastAg = lastAgMap[title];
            data.lastAmount = lastAg.values || 0;
            data.change = this.calculateChange(data.amount, data.lastAmount);
        }

        return data;
    };

    currencyDataModifier(res: DashboardModuleData) {
        res.currency = this.currency;
        if (res.amount) {
            res.amount /= 100;
        }
        if (res.lastAmount) {
            res.lastAmount /= 100;
        }
    }

    aggregationToCurrencyData(title: string, agMap: AggregationMap, lastAgMap: AggregationMap) {
        const res = this.aggregationToNumberData(title, agMap, lastAgMap);
        this.currencyDataModifier(res);
        return res;
    };

    aggregationToTimeData(title: string, agMap: AggregationMap, lastAgMap: AggregationMap) {
        return this.aggregationToNumberData(title, agMap, lastAgMap);
    };

    aggregationToDistanceData(title: string, agMap: AggregationMap, lastAgMap: AggregationMap) {
        const res = this.aggregationToNumberData(title, agMap, lastAgMap);
        res.unit = this.units;
        return res;
    };

    funcToData(
        func: (funcAgMap: AggregationMap) => number,
        agMap: AggregationMap,
        lastAgMap: AggregationMap,
        dataModifier?: (d: DashboardModuleData) => void
    ) {

        if (!agMap) { return { loaded: false }; }

        const value = func(agMap);
        if (!isNumber(value)) {
            return { loaded: false };
        }

        const data: DashboardModuleData = {
            loaded: true,
            amount: value || 0,
        };

        if (lastAgMap) {
            data.lastAmount = func(lastAgMap);
            data.change = this.calculateChange(data.amount, data.lastAmount);
        }

        if (dataModifier) {
            dataModifier(data);
        }

        return data;
    }

    valueToData(
        value: number,
        lastValue?: number,
    ) {
        return {
            loaded: true,
            amount: value || 0,
            change: this.calculateChange(value, lastValue),
        };
    };

    calculateChange(value: number, lastValue: number) {
        if (isNumber(value) && isNumber(lastValue)) {
            return (value - lastValue) / lastValue;
        }

        return undefined;
    }

    setDashboardVersion(version: DashboardVersion) {
        localStorage.setItem(dashboardVersionLsKey, version);
        this._dashboardVersion$.next(version);
    }
}
