import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { PlusAuthenticationService } from '../../core/public-api';
import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../services/details-helper.service';
import { jobToolFeature } from '../job-tool.reducer';

@Component({
  selector: 'app-job-header',
  standalone: true,
  imports: [
    FreyaCommonModule,
  ],
  templateUrl: './job-header.component.html',
  styleUrl: './job-header.component.scss'
})
export class JobHeaderComponent {
  loaded$ = this.store.select(jobToolFeature.jobLoaded);
  job$ = this.store.select(jobToolFeature.selectJob);
  zone$ = this.store.select(jobToolFeature.selectZone);
  customerName$ = this.store.select(jobToolFeature.selectCustomerName);

  constructor(
    public store: Store,
    public detailsHelper: DetailsHelperService,
    public authSvc: PlusAuthenticationService,
  ) {
  }
}
