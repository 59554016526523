<div class="card" *ngIf="sources$ | async as sources">
  <div class="dashboard-card-header p-mb-4">
    <div>
      <h4 class="p-mb-1">Lead Source<app-error-icon *ngIf="sources.error"></app-error-icon></h4>
      {{ sources.period }}
    </div>
    <app-dashboard-info-v2>
      <div class="p-mb-2">
        A breakdown of all jobs created in the selected period into their various sources ("how did you hear about us?").
      </div>
      <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="sources.queryParams" target="_blank">View Report</a>.
    </app-dashboard-info-v2>
  </div>
  <p-blockUI [target]="blockable" [blocked]="sources.loading" [autoZIndex]="false">
    <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
  </p-blockUI>
  <app-blockable #blockable>
    <app-dashboard-empty-state *ngIf="sources.isEmpty" text="No jobs created during the selected period with the selected currency"></app-dashboard-empty-state>
    <ng-container *ngIf="!sources.isEmpty">
      <div *ngFor="let source of sources.data" class="source">
        <div class="source-data">
          <div>
            <a
              *ngIf="source.source !== 'Not specified'"
              class="source-label"
              [routerLink]="['/jobs']"
              [queryParams]="{ sources: source.source }"
              target="_blank"
              >{{ source.source }}</a>
            <span *ngIf="source.source === 'Not specified'" class="source-label">{{ source.source}}</span>
            <span [pTooltip]="source.tooltip"> ({{ source.percent | number : '1.2-2' }}%)</span>
          </div>
          <div>
            {{ source.amount | currency : source.currency }}
          </div>
        </div>
        <p-progressBar [value]="source.percent" [showValue]="false" [style]="{ height: '10px' }"></p-progressBar>
      </div>
    </ng-container>
  </app-blockable>
</div>
