import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { clone } from 'lodash';
import { MenuItem } from 'primeng/api';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';
import { SubSink } from 'subsink';

import { FullJobFragment } from '../../../generated/graphql.generated';

import { HistoryService } from '../../history/history.service';
import { hasUncompletedEvents, isJobSharedToZone } from '../../jobs/jobs.util';
import { FRONTEND_PERMISSIONS } from '../../permissions.constants';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { PermissionService } from '../../services/permission.service';
import { MI_JOB_ACTIONS_PROMOTE, PromoteJobService } from '../../services/promote-job.service';
import { parseMenuItemCategoriesVisible } from '../../utilities/menu-item.util';
import { DownloadJobInvoiceComponent } from '../download-job-invoice/download-job-invoice.component';

const MI_JOB_ACTIONS = 'job-actions';

@Component({
  selector: 'app-job-actions',
  templateUrl: './job-actions.component.html',
  styleUrls: ['./job-actions.component.scss']
})
export class JobActionsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('downloadInvoice') invoiceRef: DownloadJobInvoiceComponent;

  @Input() job: FullJobFragment;

  subs = new SubSink();

  hasUpdatePermission = false;

  hasUpdateArchivedPermissions = false;

  viewPageAction: MenuItem = {
    id: 'view-page',
    label: 'View Job Page',
    icon: 'pi pi-external-link',
    command: () => {
      this.router.navigate(['job/', this.job.id]);
    },
    visible: true,
  };

  viewV2PageAction: MenuItem = {
    id: 'view-v2-page',
    label: 'View V2',
    icon: 'pi pi-external-link',
    command: () => {
      this.router.navigate(['job/v2/', this.job.id]);
    },
    visible: false,
  };

  processAction: MenuItem = {
    id: 'process',
    label: 'Process',
    icon: 'pi pi-arrow-right',
    disabled: false,
    command: () => {
      this.router.navigate(['estimating', this.job?.id]);
    },
    visible: false,
  };

  downloadInvoiceAction: MenuItem = {
    id: 'invoice',
    label: 'Download Invoice',
    icon: 'pi pi-download',
    disabled: true,
    command: () => {
      this.invoiceRef.downloadInvoice();
    },
    visible: false,
  };

  promoteAction: MenuItem = this.promoteJobService.generatePromoteAction(undefined, (transition) => {
    // this.router.navigate(['/job', transition.newJobId]);
    this.detailsHelper.pushUpdate({
      id:this.job.id,
      type:'Jobs',
      action:'update'
    });

  });

  editJobAction: MenuItem = {
    id: 'edit',
    label: 'Edit',
    icon: 'pi pi-pencil',
    disabled: false,
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'update',
        objectType: 'job',
        object: this.job,
      });
    },
    visible: false,
  };

  toggleArchivedAction: MenuItem = {
    id: 'toggle-archive',
    // Label will be set on init as we need the job to have loaded
    // so we know whether the label should say 'Archive' or 'Unarchive'
    label: undefined,
    icon: 'pi pi-folder',
    disabled: false,
    command: () => this.freyaHelper.toggleJobArchived(this.job),
    visible: false,
  };

  viewHistoryAction: MenuItem = {
    id: 'history',
    label: 'View History',
    icon: 'pi pi-book',
    command: () => {
      if (!this?.job?.id) { return; }
      this.historyService.openHistory('Job', [ this.job.id ]);
    },
    visible: false,
  };

  sendDocumentAction: MenuItem = {
    id: 'send-documents',
    label: 'Send Document',
    icon: 'pi pi-send',
    disabled: false,
    command: () => this.documentHelper.openDocumentsDialog({ jobId: this.job.id, jobCode: this.job.code }),
  };

  toggleOpenJobAction: MenuItem = {
    id: 'toggle-open',
    // Label and icon will be set on init as we need the job to have loaded
    // so we know whether the label should say 'Archive' or 'Unarchive'
    label: undefined,
    icon: undefined,
    disabled: false,
    command: () => {
      if (this.job?.closedAt) {
        this.freyaHelper.openClosedJob(this.job.id);
      } else {
        this.freyaHelper.openCloseJobDialog(this.job);
      }
    },
    visible: false,
  };

  deleteJobAction: MenuItem = {
    id: 'delete',
    label: 'Delete Job',
    icon: 'pi pi-trash',
    disabled: false,
    command: () => this.freyaHelper.openDeleteJobDialog(this.job),
    visible: false,
  };

  jobActions = [{
    label: 'Job Actions',
    id: MI_JOB_ACTIONS,
    visible: false,
    items: [
      this.viewPageAction,
      this.viewV2PageAction,
      this.processAction,
      this.downloadInvoiceAction,
      this.promoteAction,
      this.editJobAction,
      this.toggleArchivedAction,
      this.toggleOpenJobAction,
      this.deleteJobAction,
      this.viewHistoryAction,
      this.sendDocumentAction,
    ]
  }] as MenuItem[];

  disableIf = {
    isJobClosed: [ 'edit', MI_JOB_ACTIONS_PROMOTE ],
    isJobArchived: [ 'edit', 'process', 'toggle-open', 'invoice', 'send-documents', 'delete', MI_JOB_ACTIONS_PROMOTE ],
    isJobDeleted: [
      'view-page',
      'edit',
      'process',
      'toggle-archive',
      'toggle-open',
      'invoice',
      'send-documents',
      'delete', MI_JOB_ACTIONS_PROMOTE
    ],
    hasUncompletedEvents: [ 'toggle-archive', 'toggle-open' ],
    hasPendingTransactions: [ 'toggle-open' ],
    shared: [ 'edit', 'process', 'toggle-archive', 'toggle-open', 'invoice', 'send-documents', 'delete', MI_JOB_ACTIONS_PROMOTE],
  };

  constructor(
    private promoteJobService: PromoteJobService,
    private permissionHandler: PermissionService,
    private freyaMutate: FreyaMutateService,
    private historyService: HistoryService,
    private router: Router,
    private detailsHelper: DetailsHelperService,
    private recentItems: RecentItemsService,
    private freyaHelper: FreyaHelperService,
    private brandingService: BrandingService,
    private documentHelper: DocumentHelperService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();

    this.subs.sink = this.detailsHelper.getObjectUpdates('Jobs').subscribe((res) => {
      this.updateActions();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(){
    if (!this.job) { return; }

    this.updateActions();
  }

  initializePermissions() {
    this.subs.sink = this.brandingService.watchFeature('jobs-v2')
    .subscribe((enabled) => {
      this.viewV2PageAction.visible = enabled;
    });

    // this.subs.sink = this.brandingService.watchFeature('jobs-v2')
    //   .subscribe((enabled) => {
    //     this.viewV2PageAction.visible = enabled;

    //   });

    this.subs.sink = this.permissionHandler
      .watchPermissionsAndRestrictions([
        { permission: 'artifacts.list' },
        { permission: 'artifacts.send' },
        { permission: 'jobs.update' },
        { permission: 'jobs.update', restriction: { updateArchived: true }},
        { permission: 'history.list' },
        { permission: FRONTEND_PERMISSIONS.estimating },
        { permission: 'jobs.delete' },
        { permission: 'jobs.viewV2' },
      ])
      .subscribe(([
        pArtifactsList,
        pArtifactsSend,
        pJobsUpdate,
        rUpdateArchived,
        pHistoryList,
        pViewEstimating,
        pJobsDelete,
        pJobsViewV2,
      ]) => {

        this.downloadInvoiceAction.visible = pArtifactsList;
        this.sendDocumentAction.visible = pArtifactsSend;
        this.editJobAction.visible = pJobsUpdate;
        this.toggleOpenJobAction.visible = pJobsUpdate;

        // Hide delete until we implement restore
        // this.deleteJobAction.visible = pJobsDelete;

        this.viewHistoryAction.visible = pHistoryList;
        this.processAction.visible = pViewEstimating;
        this.promoteAction.visible = pJobsUpdate;
        // this.viewV2PageAction.visible = pJobsViewV2;

        this.hasUpdateArchivedPermissions = rUpdateArchived;

        // mark categories as visible based on if their children are visible
        parseMenuItemCategoriesVisible(this.jobActions);

        this.updateActions();

        // Clone job actions so angular change detection propogates this var
        this.jobActions = clone(this.jobActions);
      });
  }

  checkPromotionItem() {
    const jobActionsItems = this.jobActions.find((a) => a.id === MI_JOB_ACTIONS)?.items;

    this.promoteJobService.checkPromoteActionMenuItem(this.job, jobActionsItems);

    this.promoteAction.visible = this.hasUpdatePermission;
  }

  /**
   * Updates any actions whose label, icon, visibility or disabled status depends on the state of the job.
   */
  updateActions() {
    this.checkPromotionItem();
    this.updateToggleActions();
    this.setDisabledActions();
    this.recentItems.setPinAction(this.jobActions, this.job, 'job');
  }

  /**
   * Sets the disabled status of every visible action.
   * If an action is set to disabled, an appropriate tooltip is set.
   */
  setDisabledActions() {
    if(!this.job || !this.jobActions.length) {return; }

    const [ actionsMenu ] = this.jobActions;
    const { items: actions } = actionsMenu;

    for (const action of actions) {
      if (!action.visible) { continue; }
      const { disabled, tooltipLabel } = this.getActionState(action);
      action.disabled = disabled;
      action.tooltipOptions = disabled ? { tooltipLabel, tooltipPosition: 'left' } : undefined;
    };
  }

  /**
   * Resolves whether a given action should be disabled
   * and what its tooltip should say based on the state of the job.
   */
  getActionState(action: MenuItem): { disabled: boolean; tooltipLabel: string | undefined } {

    const isJobArchived = Boolean(this.job?.archivedAt);

    const isJobClosed = Boolean(this.job?.closedAt);

    const isJobDeleted = Boolean(this.job?.deletedAt);

    const isJobShared = isJobSharedToZone(this.job, this.brandingService.currentZone().value.id);

    const hasPendingTransactions = Boolean(this.job?.transactions?.filter((t) => t.stage === 'pending')?.length);

    if (isJobArchived && this.disableIf.isJobArchived.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: `${action.label} not available when job is archived`,
      };
    }

    if (isJobClosed && this.disableIf.isJobClosed.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: `${action.label} not available when job is closed`,
      };
    }

    if (isJobDeleted && this.disableIf.isJobDeleted.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: `${action.label} not available when job has been deleted`,
      };
    }

    if (hasUncompletedEvents(this.job) && this.disableIf.hasUncompletedEvents.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: 'This job has uncompleted events',
      };
    }

    if (hasPendingTransactions && this.disableIf.hasPendingTransactions.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: 'This job has pending transactions',
      };
    }

    if (isJobShared && this.disableIf.shared.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: `This job is shared, it can only be edited from its original zone`,
      };
    }

    return {
      disabled: false,
      tooltipLabel: undefined,
    };
  }

  /**
   * Updates any actions whose label, icon or visibility depends on the state of the job.
   */
  updateToggleActions() {
    const isJobArchived = Boolean(this.job?.archivedAt);
    const isJobClosed = Boolean(this.job?.closedAt);

    this.toggleArchivedAction.label = isJobArchived ? 'Unarchive' : 'Archive';
    this.toggleArchivedAction.visible = this.hasUpdateArchivedPermissions && isJobClosed;

    this.toggleOpenJobAction.label = isJobClosed ? 'Open' : 'Close';
    this.toggleOpenJobAction.icon = isJobClosed ? `pi pi-circle` : 'pi pi-circle-fill';
  }
}
