import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FetchPolicy } from '@apollo/client/core';

import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { concatMap, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { OBJECT_ICON_MAP } from 'src/app/global.constants';
import { OnlineStatusService } from 'src/app/online-status.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';

import { AttentionItemsTotalGQL } from '../../../generated/graphql.generated';

import { AppMainComponent } from '../../app.main.component';
import { AttentionItemsService } from '../../attention-items/attention-items.service';
import { ColorSchemeService } from '../../color-scheme.service';
import { ScheduleActions } from '../../schedules/store/schedule.actions';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';

import { MenuService } from './app.menu.service';

export const recentItemsAnimation = trigger('recentItems', [
    transition(':enter', [
        style({ opacity: 0, marginTop: '-14px' }), animate('300ms', style({ opacity: 1, marginTop: '0' }))]
    )
]);

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss'],
    animations: [recentItemsAnimation]
})
export class AppMenuComponent implements OnInit {

    @Input() sidebarStatic = false;
    @Input() sidebarMobileActive = false;

    entityIcons = OBJECT_ICON_MAP;

    isSideBarDisabled = false;

    attentionItemsTotal$ = merge(
        this.detailsHelper.getObjectUpdates(['Jobs', 'Events']),
        this.brandingService.currentZone(),
    ).pipe(
        // If zone changed, check cache first, otherwise use network as cache will be outdated
        map((update) => 'domain' in update ? 'cache-first' : 'no-cache'),
        switchMap((fetchPolicy: FetchPolicy) => this.attentionItemsService.fetchTotal(fetchPolicy)),
    );

    constructor(
        public appMain: AppMainComponent,
        public menuService: MenuService,
        public recentItems: RecentItemsService,
        public branding: BrandingService,
        public colorSchemeService: ColorSchemeService,
        private detailsHelper: DetailsHelperService,
        private brandingService: BrandingService,
        private attentionItemsService: AttentionItemsService,
        private onlineStatusService: OnlineStatusService,
        private store: Store,
    ) { }

    ngOnInit(): void {
        this.onlineStatusService.isOnline$.subscribe(status => {
            this.isSideBarDisabled = status;
        });

        this.store.dispatch(ScheduleActions.initSchedulesComponent());

    }

    trackID(index, obj) {
        return obj.id || obj.label;
    }

}
