import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { SearchResult } from 'minisearch';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';

import { BehaviorSubject, Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { User } from '../../../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';

import { EventAttendeeRoles } from '../../../../global.constants';
import { FreyaNotificationsService } from '../../../../services/freya-notifications.service';
import { DispatchAssetSearchService, PotentialAssetType } from '../../dispatch-asset-search.service';
import { DispatchUserSearchService, PotentialCrewType } from '../../dispatch-user-search.service';
import { DispatchActions } from '../../store/dispatch.actions';
import { AttendeeWithName, DispatchEvent, DispatchFeature, TrucksWithMetadata } from '../../store/dispatch.reducer';
import { DispatchChipComponent } from '../dispatch-chip/dispatch-chip.component';


type KeyExtractor<T> = (item: T) => string;
const crewKeyExtractor = (item: AttendeeWithName) => `${item.user.id}-${item.role}`;
const truckKeyExtractor = (item: TrucksWithMetadata) => `${item.id}`;

@Component({
  selector: 'app-dispatch-edit-event',
  standalone: true,
  imports: [FreyaCommonModule, DispatchChipComponent, CdkDropList],
  providers: [DispatchUserSearchService, DispatchAssetSearchService],
  templateUrl: './dispatch-edit-event.component.html',
  styleUrl: './dispatch-edit-event.component.scss',
})
export class DispatchEditEventComponent implements OnInit, OnDestroy {
  @Input() event: DispatchEvent;
  @Output() closeDialog = new EventEmitter<void>();

  public readonly EVENT_ATTENDEE_ROLES = EventAttendeeRoles;

  public eventCrew: AttendeeWithName[] = [];
  public eventTrucks: TrucksWithMetadata[] = [];

  public selectedCrewMember: User;
  public selectedAsset: User;

  public potentialCrew$ = this.store.select(DispatchFeature.selectPotentialCrew);
  public potentialAssets$ = this.store.select(DispatchFeature.selectPotentialAssets);
  public updatingCrew$ = this.store.select(DispatchFeature.updateCrewLoading);

  public filteredPotentialCrew$: Observable<PotentialCrewType | SearchResult[]>;
  public filteredPotentialAssets$: Observable<PotentialAssetType | SearchResult[]>;

  private userSearchControl = new BehaviorSubject<string>('');
  private assetSearchControl = new BehaviorSubject<string>('');

  private subs = new SubSink();

  constructor(
    private store: Store,
    private notify: FreyaNotificationsService,
    private userSearchService: DispatchUserSearchService,
    private assetSearchService: DispatchAssetSearchService,
  ) { }

  ngOnInit(): void {

    this.eventCrew = cloneDeep(this.event.crew);
    this.eventTrucks = cloneDeep(this.event.trucks);
    this.watchUserSearch();
    this.watchAssetSearch();
  }

  private watchUserSearch() {
    this.filteredPotentialCrew$ = this.userSearchService.searchUsers(this.userSearchControl, this.potentialCrew$);
  }

  private watchAssetSearch() {
    this.filteredPotentialAssets$ = this.assetSearchService.searchAssets(this.assetSearchControl, this.potentialAssets$);
  }

  public onClose() {
    this.subs.unsubscribe();
    this.closeDialog.emit();
  }

  public onSave() {
    const { added: addAttendees, removed: removeAttendees } = this.findAddedAndRemovedResources(
      this.event.crew,
      this.eventCrew,
      crewKeyExtractor,
    );

    const { added: addAssets, removed: removeAssets } = this.findAddedAndRemovedResources(
      this.event.trucks,
      this.eventTrucks,
      truckKeyExtractor,
    );

    if (addAttendees.length === 0 && removeAttendees.length === 0 && addAssets.length === 0 && removeAssets.length === 0) {
      this.onClose();
      return;
    }

    this.store.dispatch(
      DispatchActions.updateCrew({
        edits: [
          {
            eventId: this.event.event.id,
            eventName: this.event.customer.name,
            addAttendees,
            removeAttendees,
            addAssets,
            removeAssets,
          },
        ],
      }),
    );

    // Assuming you have a selector to check the success of the update operation
    const updateSuccess$ = this.store.select(DispatchFeature.updateCrewLoaded);
    const updateFailed$ = this.store.select(DispatchFeature.updateCrewError);

    // Subscribe to the success indicator
    this.subs.sink = updateSuccess$.subscribe((success) => {
      if (success) {
        this.onClose();
      }
      // If not successful, do nothing and let the user adjust as needed
    });

    this.subs.sink = updateFailed$.subscribe((failed) => {
      if (failed) {
        this.eventCrew = cloneDeep(this.event.crew);
      }
    });
  }

  public searchUser($event: AutoCompleteCompleteEvent) {
    this.userSearchControl.next($event.query);
  }

  public searchAsset($event: AutoCompleteCompleteEvent) {
    this.assetSearchControl.next($event.query);
  }

  public addCrewMember($event: AutoCompleteSelectEvent) {
    const attendee = $event.value as AttendeeWithName;

    if (!attendee) {
      return;
    }

    const { user, role: initialRole, name, eventsCount, hasConflict } = attendee;
    const { id: userId } = user;
    let role = initialRole;

    // Check if the user is already in the crew or if a crew lead already exists
    const isUserInCrew = this.eventCrew.some(({ user }) => user.id === userId);
    const crewLeadAlreadyExists = role === EventAttendeeRoles.crewLead &&
      this.eventCrew.some((member) => member.role === EventAttendeeRoles.crewLead);

    if (isUserInCrew) {
      return this.notify.error(`Crew member already added`);
    }

    if (!crewLeadAlreadyExists && this.eventCrew.length === 0) {
      role = EventAttendeeRoles.crewLead; // Add the first user as crew lead if no crew lead exists
    } else if (crewLeadAlreadyExists && role === EventAttendeeRoles.crewLead) {
      role = EventAttendeeRoles.crewMember; // If crew lead exists, add the user as crew member

    }

    this.eventCrew.push({ user, role, name, eventsCount, hasConflict });

    this.selectedCrewMember = null;
  }

  public addAsset($event: AutoCompleteSelectEvent) {
    const asset = $event.value as TrucksWithMetadata;

    if (!asset) {
      return;
    }

    const { id, name, eventsCount, hasConflict, type } = asset;

    // Check if the user is already in the crew or if a crew lead already exists
    const assetAlreadyExists = this.eventTrucks.some(({ id: assetId }) => assetId === id);

    if (assetAlreadyExists) {
      return this.notify.error('Crew member already added');
    }

    this.eventTrucks.push({ id, name, eventsCount, hasConflict, type } as TrucksWithMetadata);

    this.selectedAsset = null;
  }

  public removeCrewMember(crewMember: AttendeeWithName) {
    this.eventCrew = this.eventCrew.filter((member) => member.user.id !== crewMember.user.id);

    // If the removed member was a crew lead and there are other members available
    if (crewMember.role === EventAttendeeRoles.crewLead && this.eventCrew.length > 0) {
      // Find the first crew member to promote to crew lead
      const newCrewLead = this.eventCrew.find(member => member.role === EventAttendeeRoles.crewMember);
      if (newCrewLead) {
        newCrewLead.role = EventAttendeeRoles.crewLead;
      }
    }
  }

  public removeTruck(truckToRemove: TrucksWithMetadata) {
    this.eventTrucks = this.eventTrucks.filter((truck) => truck.id !== truckToRemove.id);
  }

  // TODO: Move this to a utility service as this is a generic function
  /**
   * Determines the items added and removed between two lists of items based on a key extractor function.
   * The keyExtractor function should be a function that takes an item as input and returns a string key.
   * The key should be unique for each item and should be used to compare the lists.
   * The key should contains items to compare seperated by '-'.
   *
   * @param original - The original list of items.
   * @param modified - The modified list of items.
   * @param keyExtractor - A function to extract a unique key from each item to compare the lists.
   * @returns An object containing two arrays: `added` and `removed`.
   *
   * @example
   * // Example keyExtractor function for comparing based on user id and role
   * const keyExtractor = (item: AttendeeWithName) => `${item.user.id}-${item.role}`;
   * const { added, removed } = getAddedAndRemovedCrewMembers(original, modified, keyExtractor);
   */
  private findAddedAndRemovedResources<T>(original: T[], modified: T[], keyExtractor: KeyExtractor<T>) {
    // Create maps for quick lookup
    const originalMap = new Map<string, T>();
    const modifiedMap = new Map<string, T>();

    // Populate the maps
    original.forEach((item) => originalMap.set(keyExtractor(item), item));
    modified.forEach((item) => modifiedMap.set(keyExtractor(item), item));

    // Find removed items (items present in originalMap but not in modifiedMap)
    const removed = original.filter((item) => !modifiedMap.has(keyExtractor(item)));

    // Find added items (items present in modifiedMap but not in originalMap)
    const added = modified.filter((item) => !originalMap.has(keyExtractor(item)));

    return { added, removed };
  }

  /**
   * This function is called when a crew member is dragged and dropped inside the list.
   * Workflow: When a crew member is dropped at the top of the list, they become the crew lead.
   * @param $event Drag event which carries user data
   */
  public drop($event: CdkDragDrop<AttendeeWithName[]>) {
    /**
     * We need to convert the dropped member to a crew leader.
     * Hence, we will remove the member from the list and add them back as a crew leader.
     * Also, if there is an existing crew leader, we will remove them and add them back as a crew member.
     */

    if ($event.currentIndex === 0) {
      const userDetails = $event.item.data;

      // Prepare the new crew lead
      const newCrewLead = { ...userDetails, role: EventAttendeeRoles.crewLead };

      // Find and prepare the previous crew lead
      const previousCrewLead = this.eventCrew.find((member) => member.role === EventAttendeeRoles.crewLead);

      // Prepare the list of attendees to be removed
      const removeAttendees = [userDetails];
      if (previousCrewLead) {
        removeAttendees.push(previousCrewLead);
      }

      // Prepare the list of attendees to be added
      const addAttendees = [newCrewLead];
      if (previousCrewLead) {
        addAttendees.push({ ...previousCrewLead, role: EventAttendeeRoles.crewMember });
      }

      // Create a set of IDs to be removed
      const removeAttendeeIds = new Set(removeAttendees.map((attendee) => attendee.user.id));

      // Filter out the attendees to be removed and add the new attendees
      this.eventCrew = this.eventCrew.filter((attendee) => !removeAttendeeIds.has(attendee.user.id)).concat(addAttendees);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
